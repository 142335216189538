import { ClientConfigDto } from '../services/service';

class Settings {
    public AdB2CClientId: string | undefined;
    public Production: boolean | undefined;
    public AppInsightsConnectionString: string | undefined;
    public Authority: string | undefined;
    public NoWorkAccountAuthority: string | undefined;
    public KnownAuthorities: string[] | undefined;
    public BootstrapClientId: string | undefined;

    public setSettings(settings: ClientConfigDto) {
        this.AdB2CClientId = settings.clientId;
        this.Production = settings.production;
        this.AppInsightsConnectionString = settings.appInsightsConnectionString;
        this.Authority = `${settings.instanceUrl}/${settings.b2CDomain}/${settings.signInPolicy}`;
        this.NoWorkAccountAuthority = `${settings.instanceUrl}/${settings.b2CDomain}/${settings.signInNoWorkAccountPolicyId}`;
        this.KnownAuthorities = [settings.instanceUrl!];
        this.BootstrapClientId = settings.bootstrapClientId;
    }
}

export default new Settings();