export const APP_URLS = {
    subscriptionAgreement: 'https://www.syskit.com/_app-links/subscriptions/agreement',
    contactSupport: 'https://www.syskit.com/_app-links/subscriptions/contact-support/',
    buyNow: 'https://www.syskit.com/_app-links/point/trial-buy-now',
    comparePlans: 'https://www.syskit.com/_app-links/point/compare-plans/',
    learnMore: 'https://www.syskit.com/_app-links/point/documentation/',
    startTrial: 'https://www.syskit.com/_app-links/point/start-trial/',
    gaNeededWhy: 'https://www.syskit.com/_app-links/point/ga-needed-reason',
    faq: 'https://www.syskit.com/_app-links/subscriptions/faq',
    eula: 'https://www.syskit.com/_app-links/subscriptions/agreement/',
    licensedUsersCount: 'https://www.syskit.com/_app-links/point/licensed-users/learn-more/',
    auditLogsRetention: 'https://www.syskit.com/_app-links/point/audit-logs-retention',
    trialExpiredForm: 'https://www.syskit.com/_app-links/subscriptions/trial-expired',
    inviteUserLearnMore: 'https://www.syskit.com/_app-links/subscriptions/invite-user'
};
