import React from 'react';
import appInsights from '../../common/applicationInsights';
import TrackedEventProcessEnum from '../../common/trackedEventProcessEnum';
import TrackedEventTypeEnum from '../../common/trackedEventTypeEnum';

interface ContactUsEmailLinkProps {
    children: React.ReactNode;
}

export const ContactUsEmailLink: React.FC<ContactUsEmailLinkProps> = ({ children }) => {
    const contactUsClicked = () => {
        appInsights.trackEvent("Contact Sales Clicked",
            TrackedEventTypeEnum.ContactedSysKit,
            TrackedEventProcessEnum.Default);
    }

    return (
        <a href="mailto:sales@syskit.com" onClick={contactUsClicked}>{children}</a>);
}
