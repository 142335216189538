import { AccountInfo } from "@azure/msal-browser";
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, generatePath } from 'react-router-dom';
import SHAPESPATTERN from '../../assets/images/shapes-pattern.svg';
import { ErrorBoundary } from "../../components/errorBoundary/errorBoundary";
import Spinner, { ClipSpinner } from '../../components/spinner';
import { ReconnectTenantBanner } from "../../containers/reconnectTenantBanner/reconnectTenantBanner";
import { ClientApplication } from '../../msal/clientApp';
import ROUTES from '../../routes/routes';
import { SubscriptionActivationProgressStatusEnum, SubscriptionStateDtoEnum, SysKitSubscriptionsClient, TenantSubscriptionDto } from '../../services/service';
import { ActivateSubscriptionWizard } from "../activateSubscriptionWizard/activateSubscriptionWizard";
import ControlCenterNavigation from '../controlCenterNavigation/controlCenterNavigation';
import MySubscriptions from '../mySubscriptions/mySubscriptions';
import SubscriptionAlreadyActivated from '../subscriptionAlreadyActivated/subscriptionAlreadyActivated';
import SubscriptionResolver from '../subscriptionResolver/subscriptionResolver';
import './page.scss';
import PageHeader from './pageHeader';
import PageLayout from './pageLayout';
import PageScrollContainer from "./pageScrollContainer";
import PageTitle from './pageTitle';
import { SubscriptionContext, SubscriptionContextProps } from './subscriptionContext/subscriptionContext';

const page = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [subscriptionActivated, setSubscriptionActivated] = useState<boolean>(false);
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionContextProps | undefined>(undefined);

    useEffect(
        () => {
            const account = ClientApplication.instance.getActiveAccount();
            if (account) {
                getSubscriptionForUser(account);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const getSubscriptionForUser =
        async (account: AccountInfo) => {
            try {
                setLoading(true);
                const syskitSubscriptionsClient = new SysKitSubscriptionsClient();

                const subscriptionResult: TenantSubscriptionDto = await syskitSubscriptionsClient.getSubscriptionForUser();
                setSubscriptionActivated(subscriptionResult && subscriptionResult.activationProgressStatus === SubscriptionActivationProgressStatusEnum.Completed);
                setSubscriptionData({
                    subscriptionId: subscriptionResult?.subscriptionId,
                    activationProgressStatus: subscriptionResult?.activationProgressStatus,
                    activationProgressMessage: subscriptionResult?.activationProgressMessage,
                    pointInstanceUrl: subscriptionResult?.pointInstanceUrl,
                    areResourcesProvisioned: subscriptionResult?.areResourcesProvisioned,
                    licenseExpired: subscriptionResult?.licenseExpired,
                    state: subscriptionResult?.state
                });

                setLoading(false);
            }
            catch (e) {
                console.error(e);
            }
            finally {
                setLoading(false);
            }
        }

    const isVisibleReconnectBanner = !subscriptionData?.areResourcesProvisioned && !subscriptionData?.licenseExpired && subscriptionData?.state === SubscriptionStateDtoEnum.Active;

    return (
        <PageLayout>
            <PageHeader />
            <ErrorBoundary>
                {loading ?
                    <Spinner text='Loading...' className='tw-w-full tw-flex-1'>
                        <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                    </Spinner>
                    :
                    <BrowserRouter>
                        <PageTitle />

                        <Switch>
                            {isVisibleReconnectBanner && <Route path={ROUTES.CONTROL_CENTER.ROUTE} component={ReconnectTenantBanner} />}
                        </Switch>

                        <SubscriptionContext.Provider value={{ subscriptionData, setSubscriptionData }}>
                            <PageScrollContainer>
                                <img src={SHAPESPATTERN} className="tw-absolute tw-right-0 tw-top-1/4 tw-hidden sm:tw-block"></img>
                                <div className="scroll-content tw-py-4">
                                    <Switch>
                                        {!loading &&
                                            <Route exact path="/">
                                                {subscriptionActivated && <Redirect to={generatePath(ROUTES.CONTROL_CENTER.ROUTE, { subscriptionId: subscriptionData?.subscriptionId })} />}
                                                {!subscriptionActivated && <Redirect to={ROUTES.ACTIVATE_SUBSCRIPTION_FORM.ROUTE} />}
                                            </Route>
                                        }
                                        <Route path={ROUTES.SUBSCRIPTION_RESOLVER.ROUTE} component={SubscriptionResolver} />
                                        <Route exact path={ROUTES.MY_SUBSCRIPTIONS.ROUTE} component={MySubscriptions} />
                                        <Route exact path={ROUTES.ACTIVATE_SUBSCRIPTION_FORM.ROUTE} component={ActivateSubscriptionWizard} />
                                        <Route path={ROUTES.CONTROL_CENTER.ROUTE} component={ControlCenterNavigation} />
                                        <Route exact path={ROUTES.SUBSCRIPTION_ALREADY_ACTIVATED.ROUTE} component={SubscriptionAlreadyActivated} />
                                        <Redirect to="/" />
                                    </Switch>
                                </div>
                            </PageScrollContainer>
                        </SubscriptionContext.Provider>
                    </BrowserRouter>
                }
            </ErrorBoundary>
        </PageLayout>

    )
}

export default page;