import React from 'react';
import { SubscriptionsOverviewPlan } from 'services/service';
import { APP_URLS } from '../../../appUrls';
import Icon, { IconEnum } from '../../../components/icon';
import OuterLink from '../../../components/outerLink/outerLink';

import './planDetails.scss';

interface PlanDetailsProps {
    plan: SubscriptionsOverviewPlan;
    renewalDate: Date;
    licensedUserCount?: number;
}

export const PlanDetails: React.FC<PlanDetailsProps> = ({ plan, renewalDate, licensedUserCount }) => (
    <div className="plan-details">
        <Detail title={plan.name} value={formatUnitCount(plan.unitCount, licensedUserCount)} />
        {licensedUserCount && licensedUserCount > plan.unitCount && <div className="license-message-details">
            <Icon iconName={IconEnum.AlertTriangle} width='14' height='14' className='alert-icon'></Icon>
            <div><p>You need to purchase <b>{licensedUserCount}</b> licenses.</p></div>
            <OuterLink icon href={APP_URLS.licensedUsersCount}>How we calculate licensing</OuterLink>
        </div>}
        <Detail title="Next Renewal Date" value={renewalDate.toLocaleDateString()} />
    </div>
)

const Detail: React.FC<{ title: string; value: string; }> = ({ title, value }) => (
    <div className="plan-details-detail">
        <div className="plan-details-detail__title">{title}</div>
        <div className="plan-details-detail__value">{value}</div>
    </div>
)

function formatUnitCount(purchased: number, used?: number): string {
    return used && used > purchased ? `${purchased.toLocaleString()}/${used.toLocaleString()} purchased` : `${purchased.toLocaleString()} users`;
}