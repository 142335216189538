import DataGrid, {
    Column
} from 'devextreme-react/data-grid';
import React from 'react';
import { SubscriptionAuditLogDto } from '../../../services/service';

interface ActivityLogDialogTableProps {
    logs: SubscriptionAuditLogDto[];
}

const activityLogDialogTable = ({ logs }: ActivityLogDialogTableProps) => {
    return (
        <DataGrid
            height={300}
            className='activity_log_dialog__table'
            dataSource={logs}
            showColumnLines={false}
            showRowLines={false}
            showBorders={false}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
        >
            {/* <Scrolling mode="virtual" /> */}
            <Column dataField="createdOn" caption='Created On' dataType='datetime' />
            <Column dataField="changedAttribute" caption="Changed" />
            <Column dataField="oldValue" caption="Old Value" />
            <Column dataField="newValue" caption='New Value' />
        </DataGrid>
    )
}

export default activityLogDialogTable;