import React from "react";
import NO_SUBSCRIPTIONS from '../../assets/images/noSubscriptions.svg';
import OuterLink from '../../components/outerLink/outerLink';

const AZURE_MARKETPLACE_URL = 'https://azuremarketplace.microsoft.com/en-us/marketplace/';

const noSubscriptionsScreen = () => {
    return (
        <>
            <div>
                <img src={NO_SUBSCRIPTIONS} />
            </div>
            <div className="my_subscriptions__wrapper__title">
                You currently have no active subscriptions.
            </div>
            <div className="my_subscriptions__wrapper__link">
                Set up and subscribe in <OuterLink href={AZURE_MARKETPLACE_URL} icon >Azure Marketplace</OuterLink>
            </div>
        </>
    )
}

export default noSubscriptionsScreen;