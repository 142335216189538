const ROUTES = {
    CREATE_ACCOUNT: {
        ROUTE: '/create-account',
        TITLE: '',
        SUBTITLE: ''
    },
    MY_SUBSCRIPTIONS: {
        ROUTE: '/my-subscriptions',
        TITLE: 'Syskit Subscriptions',
        SUBTITLE: 'Integrate your SaaS solution'
    },
    SUBSCRIPTION_RESOLVER: {
        ROUTE: '/resolve',
        TITLE: 'Activate Subscription',
        SUBTITLE: 'Below are the details of your Syskit subscription'
    },
    SUBSCRIPTION_RESOLVER_RESULT: {
        ROUTE: '/resolve/:id/fulfillment-activation-result',
        TITLE: '',
        SUBTITLE: ''
    },
    ACTIVATE_SUBSCRIPTION_FORM: {
        ROUTE: '/activate-subscription',
        TITLE: '',
        SUBTITLE: ''
    },
    CONTROL_CENTER: {
        ROUTE: '/control-center/:subscriptionId',
        TITLE: 'My Subscriptions',
        SUBTITLE: 'Information about your Syskit Point subscription',
        RELATIVE_LINKS: {
            OVERVIEW: '',
            USERS: 'control-center-users',
            USER_EDIT: 'users/:userId',
            ORDERS: 'control-center-orders', // TO DO
            SUBSCRIPTIONS: 'subscriptions', // TO DO
            SETTINGS: 'control-center-settings' // TO DO
        }
    },
    SUBSCRIPTION_ALREADY_ACTIVATED: {
        ROUTE: '/unauthorized',
        TITLE: 'My Subscriptions',
        SUBTITLE: 'Access not granted'
    }
};

export const ROUTES_NAME_MAPPER = {
    [ROUTES.MY_SUBSCRIPTIONS.ROUTE]: 'MY_SUBSCRIPTIONS',
    [ROUTES.SUBSCRIPTION_RESOLVER.ROUTE]: 'SUBSCRIPTION_RESOLVER',
    [ROUTES.SUBSCRIPTION_RESOLVER_RESULT.ROUTE]: 'SUBSCRIPTION_RESOLVER_RESULT',
    [ROUTES.ACTIVATE_SUBSCRIPTION_FORM.ROUTE]: 'ACTIVATE_SUBSCRIPTION_FORM',
    [ROUTES.CONTROL_CENTER.ROUTE]: 'CONTROL_CENTER',
    [ROUTES.SUBSCRIPTION_ALREADY_ACTIVATED.ROUTE]: 'SUBSCRIPTION_ALREADY_ACTIVATED'
};

export default ROUTES;
