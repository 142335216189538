import { SelectBox } from "devextreme-react";
import React from "react";
import { RegionEnum } from "../../services/service";
import './dataRegionDropdown.scss';

interface DataRegionDropdownProps {
    item?: RegionEnum;
    onChange(newItem: RegionEnum): void;
}

const dataRegionDropdownLabels = {
    [RegionEnum.USA]: 'USA',
    [RegionEnum.Europe]: 'Europe',
    [RegionEnum.Australia]: 'Asia-Pacific & Australia'
}

const dropdownDataSource = Object.keys(RegionEnum)
    .filter(x => !isNaN(Number.parseInt(x)))
    .map(x => ({ key: Number.parseInt(x), name: dataRegionDropdownLabels[x] }))

const dataRegionDropdown = ({ onChange, item }: DataRegionDropdownProps) => {
    const onValueChangedCallback = (e) => onChange(e.value);

    return (
        <SelectBox
            dataSource={dropdownDataSource}
            displayExpr={"name"}
            valueExpr={"key"}
            value={item}
            hoverStateEnabled={false}
            onValueChanged={onValueChangedCallback}
            dropDownOptions={{ maxHeight: '40vh' }}
            className="data-region-dropdown"
        />
    )
}

export default dataRegionDropdown;