import React from 'react';
import LabelDetail from '../../../components/labelDetail/labelDetail';
import RegionDropdown from '../../../components/regionDropdown/regionDropdown';
import TimeZonesDropdown from '../../../components/timeZoneDropdown/timeZoneDropdown';
import { ResolvedSubscriptionDto } from '../../../services/service';
import { useSubscriptionResolverContext } from '../subscriptionResolverContext';

const TimeZones = () => {
    const { userInput } = useSubscriptionResolverContext();
    const timeZone = userInput.timezoneDropdown;
    return (
        <TimeZonesDropdown 
            item={timeZone.item}
            onChange={timeZone.setItem!}
        />
    )
};

const Regions = () => {
    const { userInput } = useSubscriptionResolverContext();
    const region = userInput.regionDropdown;
    return (
        <RegionDropdown 
            item={region.item}
            onChange={region.setItem!}
        />    
    )
}

interface SubscriptionDetailsProps {
    subscription: ResolvedSubscriptionDto;
}

const detailsComponent = ({ subscription }: SubscriptionDetailsProps) => {
    return (
        <div className="subscription_details">
            <LabelDetail label={'Customer E-mail'} value={subscription.customerMail!} />
            <LabelDetail label={'Customer Name'} value={subscription.customerName!} />
            <LabelDetail label={'ID'} value={subscription.subscriptionId} />
            <LabelDetail label={'Name'} value={subscription.offerId!} />
            <LabelDetail label={'Status'} value={subscription.statusLabel!} />
            <LabelDetail label={'Plan'} value={subscription.planId!} />
            <LabelDetail label={'Purchaser E-mail'} value={subscription.purchaserMail!} />
            <LabelDetail label={'Azure Tenant ID'} value={subscription.purchaserTenant!} />
            <LabelDetail label={'Azure Region'} value={<Regions />} />
            <LabelDetail label={'Web Application Time Zone'} value={<TimeZones />} />
        </div>
    )
}

export default detailsComponent;