// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_detail{display:flex;flex-direction:column;min-width:300px;min-height:30px;margin-bottom:40px;text-decoration:none}
.label_detail__label{margin-bottom:14px;font-weight:700;font-size:18px;font-family:var(--secondary-font)}
.label_detail__description{margin-bottom:10px}
.label_detail__value{max-width:370px}
.label_detail__info{padding:8px 0 0 16px;color:var(--eigengrau-50);font-size:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
