import React, { useCallback, useState } from 'react';
import Button from '../../../components/button/button';
import { SubscriptionDto } from '../../../services/service';
import './activityLog.scss';
import ActivityLogDialog from './activityLogDialog';


interface ViewActivityLogProps {
    subscription: SubscriptionDto;
}

const viewActivityLog = ({ subscription }: ViewActivityLogProps) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const openDialog = useCallback(
        () => {
            setIsOpen(true)
        },
        [setIsOpen]
    )

    const closeDialog = useCallback(
        () => {
            setIsOpen(false)
        },
        [setIsOpen]
    )

    return (
        <>
            <Button
                textual
                onClick={openDialog}
            >
                View Activity Log
            </Button>
            <ActivityLogDialog
                isOpen={isOpen}
                closeDialog={closeDialog} 
                subscription={subscription}
            />
        </>
    )
}

export default viewActivityLog;