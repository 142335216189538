import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import Button from '../button/button';
import { WizardNavigation } from './navigation/wizardNavigation';
import { WizardStepProps } from './step/wizardStep';

import './wizard.scss';
import { WizardContext } from './wizardContext';

export type WizardChild = React.ReactElement<WizardStepProps>;

interface WizardProps {
    className?: string;
    title?: string;
    onFinish?(): void | Promise<void>;
    onStepChanged?(stepId: string): void;
    initialStep?: string;
    children: Array<WizardChild>;
}


export const Wizard: React.FC<WizardProps> = ({ title, className, onFinish, onStepChanged, children, initialStep }) => {
    const childProps = React.Children.map(children, child => child?.props);
    const stepsMap: Record<string, number> = childProps.reduce((acc, step, idx) => ({ ...acc, [step.stepId]: idx }), {})

    const [step, setStep] = useState(initialStep ? stepsMap[initialStep] : 0);

    const currentStep = children[step];

    const nextStep = async () => {
        if (currentStep.props.disableNext) {
            return;
        }
        if (step === children.length - 1) {
            await onFinish?.();
        } else {
            setStep(s => s + 1);
        }
    }

    const setStepById = (stepId: string) => setStep(stepsMap[stepId])

    const onClickNext = async () => {
        await currentStep.props.onNext?.();
        await nextStep();
    }

    useEffect(() => {
        onStepChanged?.(childProps[step].stepId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    if (childProps === undefined) {
        throw new Error("Invalid wizard configuration.")
    }

    return (
        <WizardContext.Provider value={{ step, setStep: setStepById, nextStep }}>
            <div className={classNames("wizard", className)}>
                <WizardNavigation
                    className="wizard__navigation"
                    title={title}
                    steps={childProps}
                />
                <div className="wizard__step">
                    {currentStep.props.children}
                    {currentStep.props.hideButton !== true && (
                        <div className="wizard__actions">
                            <Button disabled={currentStep.props.disableNext} {...currentStep.props.buttonProps} onClick={onClickNext}>Next</Button>
                        </div>
                    )}
                </div>
            </div>
        </WizardContext.Provider>
    )
}

