import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ClientApplication } from '../msal/clientApp';
import ROUTES from '../routes/routes';
import Login from './login/login';
import { Register } from './login/register';
import Page from './page/page';

import { InvitationExpired } from '../components/invitaionExpired/invitationExpired';
import './app.scss';

const app = () => {

    if (!navigator.cookieEnabled) {
        return (
            <div className="cookies-disabled">
                <h1>We can't sign you in</h1>
                <p>Your browser is currently set to block cookies.<b>You need to allow cookies to use this service.</b></p>
                <p>Cookies are small text files stored on your computer that tell us when you're signed in. To learn how to allow cookies, check the online help in your web browser.</p>
            </div>
        );
    }

    return (
        <MsalProvider instance={ClientApplication.instance}>

            <AuthenticatedTemplate>
                <Page />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={'/'} component={Login} />
                        <Route exact path={ROUTES.CREATE_ACCOUNT.ROUTE} component={Register} />
                        <Route exact path={'/invitation-expired'} component={InvitationExpired} />
                        <Redirect to="/" />
                    </Switch>
                </BrowserRouter>
            </UnauthenticatedTemplate>

        </MsalProvider >
    )
}

export default app;