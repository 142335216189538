import React from 'react';

import Loading from '../../../components/loading/loading';
import { CustomersPortalButton } from '../../../containers/customersPortalButton/customersPortalButton';
import { PlanProviderTypeEnum, SubscriptionOverviewDto, SubscriptionStateDtoEnum } from '../../../services/service';
import { isEmpty } from '../../../utils/array';
import Billing from '../billing';
import { BillingFooter } from '../billingFooter';
import { PlanDetails } from './planDetails';

import { APP_URLS } from '../../../appUrls';
import { IconEnum } from '../../../components/icon';
import Icon from '../../../components/icon/icon';
import OuterLink from '../../../components/outerLink/outerLink';
import { getAuditLogDurationText, getLicensePlans } from '../../data/plan';
import './existingSubscription.scss';

interface ExistingSubscriptionProps {
    subscriptionOverview?: SubscriptionOverviewDto;
}

export const ExistingSubscription: React.FC<ExistingSubscriptionProps> = ({ subscriptionOverview }) => {

    if (isEmpty(subscriptionOverview?.plans)
        || subscriptionOverview?.plans.find(p => p.provider === PlanProviderTypeEnum.Trial)
        || subscriptionOverview?.state === SubscriptionStateDtoEnum.Inactive) {
        return <Billing minimumLicensesNeededInfo={subscriptionOverview?.minimumLicensesNeededInfo} />
    }

    const licensePlans = getLicensePlans(subscriptionOverview?.plans!)!;

    return (
        <div className="existing-subscription">
            {subscriptionOverview === undefined
                ? <Loading />
                : (<div className='wrapper'>
                    <div>
                        <div className="existing-subscription__title">Summary</div>
                        <div className="existing-subscription__body">
                            {
                                licensePlans.map((plan, index) => (
                                    <PlanDetails
                                        key={`planDetails${index}`}
                                        plan={plan}
                                        renewalDate={new Date(subscriptionOverview.validUntil)}
                                        licensedUserCount={index == 0 ? subscriptionOverview.minimumLicensesNeededInfo?.licensedUserCount : undefined}
                                    />))
                            }
                        </div>
                        <div className='audit-log-period-banner'>
                            <div className='info-icon'>
                                <Icon width="12px" height="12px" iconName={IconEnum.Info} className="info-icon-content"></Icon>
                            </div>
                            <div>
                                <span className="text-message">We currently store your Audit logs for a period of {getAuditLogDurationText(subscriptionOverview.plans)}. If you wish to extend this retention period, please get in touch.</span>
                                <OuterLink icon href={APP_URLS.auditLogsRetention}>Contact us</OuterLink>
                            </div>
                        </div>
                        <CustomersPortalButton
                            subscriptionId={subscriptionOverview.id}
                            className='manage-subscription-button'
                            secondary
                        >
                            Manage Subscription
                        </CustomersPortalButton>
                    </div>

                    <BillingFooter />
                </div>)}
        </div>
    );
}