// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-center-navigation-container{display:flex;width:1375px;height:-moz-fit-content;height:fit-content;box-shadow:0 2px 6px var(--shadow-color)}
@media screen and (max-height: 1330px){.control-center-navigation-container{position:relative;top:10px;left:50%;transform:translate(-50%, 0%)}}
@media screen and (min-height: 1331px){.control-center-navigation-container{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}}
@media screen and (max-width: 1376px){.control-center-navigation-container{width:unset}}
.control-center-navigation-container__navigation{display:flex;flex-direction:column;min-width:175px;padding-bottom:32px;padding-left:20px;overflow:auto;background-color:var(--app-background)}
.control-center-navigation-container__category-title{margin:35px 0 15px;color:var(--secondary-grey);font-size:20px}
.control-center-navigation-container__category-title:first-child{margin-top:10px}
.control-center-navigation-container__category-title~.control-center-navigation-container__category-title{margin-top:calc(26.66667px + 4.93827vh)}
.control-center-navigation-container__category{display:flex;padding:7px 10px 7px 30px;color:var(--font-color);font-weight:400;font-size:16px;text-decoration:none;border-radius:16px 0 0 16px}
.control-center-navigation-container__category:active{text-decoration:none}
.control-center-navigation-container__category.active{padding:7px 10px 7px 30px;color:var(--white);font-weight:600;font-size:16px;background:var(--electric-indigo)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
