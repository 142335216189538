import React, { useState } from 'react';
import appInsights from '../../../common/applicationInsights';
import Button from '../../../components/button/button';
import Dialog from '../../../components/dialog/dialog';
import Loading from '../../../components/loading/loading';
import { SubscriptionUserDetailsDto, SysKitSubscriptionsClient, UserStatusEnum } from '../../../services/service';
import './removeAccess.scss';

const STRINGS = {
    TITLE: {
        CANCEL_INVITATION: 'Cancel Invitation',
        REMOVE_ACCESS: 'Remove Access'
    },
    CONFIRMATION_TEXT: {
        CANCEL_INVITATION: 'Are you sure you want to cancel the invitation for',
        REMOVE_ACCESS: 'Are you sure you want to remove access for'
    },
    SAVE_BUTTON: {
        CANCEL_INVITATION: 'Cancel Invitation',
        REMOVE_ACCESS: 'Remove Access'
    },
    SELECTED_USER: 'the selected user',
    CLOSE_BUTTON: 'Close',
    ERROR_MESSAGE: 'An error occurred while removing access for the specified user. Please try again or contact support.',
    APP_INSIGHTS: {
        INVITATION_CANCELATION: {
            ERROR_MESSAGE: 'Failed to cancel user invitation'
        },
        ACCESS_REMOVAL: {
            ERROR_MESSAGE: 'Failed to remove access for user'
        }
    }
};

interface RemoveAccessDialogProps {
    subscriptionId: string;
    user?: SubscriptionUserDetailsDto;
    closeDialog(): void;
    isOpen: boolean;
    onSuccess?: () => void;
}

const RemoveAccessDialog: React.FC<RemoveAccessDialogProps> = props => {
    const { user, subscriptionId, closeDialog, isOpen, onSuccess } = props;
    const isInvitation = user?.status != UserStatusEnum.Active;

    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const reportErrorToAppInsights = (error: any) => {
        const message = isInvitation
            ? `${STRINGS.APP_INSIGHTS.INVITATION_CANCELATION.ERROR_MESSAGE}: ${user?.email}`
            : `${STRINGS.APP_INSIGHTS.ACCESS_REMOVAL.ERROR_MESSAGE}: ${user?.id}`;

        appInsights.trackException(error, message);
    };

    //#region Actions

    const onClose = () => {
        setErrorMessage(undefined);
        closeDialog();
    };

    const onSaveButtonClicked = async () => {
        if (!user) {
            return;
        }

        setIsLoading(true);

        try {
            const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
            if (isInvitation) {
                await syskitSubscriptionsClient.cancelInvitationForSubscription(subscriptionId, user!.email!);
            }
            else {
                await syskitSubscriptionsClient.removeUserFromSubscription(subscriptionId, user!.id!);
            }

            if (!!onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error(error);
            setHasErrors(true);
            setErrorMessage(STRINGS.ERROR_MESSAGE);

            reportErrorToAppInsights(error);
        }
        finally {
            setIsLoading(false);
        }
    };

    //#endregion Actions

    //#region Rendering

    const renderFooter = () => {
        return (
            <>
                <Button
                    className="close-button"
                    onClick={onClose}
                    textual
                >{STRINGS.CLOSE_BUTTON}</Button>
                <Button
                    className="save-button"
                    onClick={onSaveButtonClicked}
                >{isInvitation ? STRINGS.SAVE_BUTTON.CANCEL_INVITATION : STRINGS.SAVE_BUTTON.REMOVE_ACCESS}</Button>
            </>
        );
    };

    const renderName = (name?: string) => {
        return (!name ? STRINGS.SELECTED_USER : <b>{name}</b>);
    };

    const renderConfirmationText = () => isInvitation
        ? <>{STRINGS.CONFIRMATION_TEXT.CANCEL_INVITATION} {renderName(user!.email)}?</>
        : <>{STRINGS.CONFIRMATION_TEXT.REMOVE_ACCESS} {renderName(user!.name)}?</>;

    //#endregion Rendering

    const showLoader = isLoading || !user;
    const showContent = !showLoader;

    return (
        <div>
            <Dialog
                title={isInvitation ? STRINGS.TITLE.CANCEL_INVITATION : STRINGS.TITLE.REMOVE_ACCESS}
                isOpen={isOpen}
                onClose={onClose}
                footer={renderFooter()}
                className="remove_access__dialog"
            >
                {showLoader && <div className="remove_access__loading"><Loading /></div>}
                {showContent &&
                    <div>
                        <div className="remove_access__content">{renderConfirmationText()}</div>
                        {hasErrors && <div className="remove_access__error">{errorMessage}</div>}
                    </div>
                }
            </Dialog>
        </div>
    );
}

export default RemoveAccessDialog;