import ROUTES from '../../routes/routes';

export interface ControlCenterNavigationItem {
    type: 'item',
    text: string;
    tooltip: string;
    id: string;
    href: string;
}

export interface ControlCenterNavigationCategory {
    type: 'category',
    text: string
}

export type ControlCenterNavigationItemsType = Array<ControlCenterNavigationItem | ControlCenterNavigationCategory>;

export const CONTROL_CENTER_NAVIGATION_ITEMS: ControlCenterNavigationItemsType = [
    {
        type: 'item',
        text: 'Overview',
        tooltip: '',
        id: 'overview',
        href: ROUTES.CONTROL_CENTER.RELATIVE_LINKS.OVERVIEW
    },
    {
        type: 'item',
        text: 'Subscriptions',
        tooltip: '',
        id: 'subscriptions',
        href: ROUTES.CONTROL_CENTER.RELATIVE_LINKS.SUBSCRIPTIONS
    },
    {
        type: 'item',
        text: 'Users',
        tooltip: '',
        id: 'users',
        href: ROUTES.CONTROL_CENTER.RELATIVE_LINKS.USERS
    }
    // {
    //     text: 'Orders',
    //     tooltip: '',
    //     id: 'orders',
    //     href: ROUTES.CONTROL_CENTER.RELATIVE_LINKS.ORDERS
    // },
    // {
    //     text: 'Settings',
    //     tooltip: '',
    //     id: 'settings',
    //     href: ROUTES.CONTROL_CENTER.RELATIVE_LINKS.SETTINGS
    // }
]