import DataGrid, {
    Column, Scrolling
} from 'devextreme-react/data-grid';
import React, { useCallback, useMemo } from 'react';
import { SubscriptionDto } from '../../services/service';
import ViewActivityLog from './actions/activityLog';

interface MySubscriptionsTableProps {
    subscriptions: SubscriptionDto[];
}

const mySubscriptionsTable = ({ subscriptions }: MySubscriptionsTableProps) => {
    const renderActionsCell = useCallback(
        (x) => {
            return (
                <ViewActivityLog subscription={x.data} />
            )
        },
        []
    )

    // added as hook not to rerender
    const table = useMemo(
        () => {
            return  (
                <DataGrid
                    height={450}
                    className="my_subscriptions__wrapper__table"
                    dataSource={subscriptions}
                    showColumnLines={false}
                    showRowLines={false}
                    showBorders={false}
                    rowAlternationEnabled={true}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                >
                    <Scrolling mode="virtual" />
                    <Column dataField="name" caption="Name" allowSorting={false} />
                    <Column dataField="offerId" caption="Product" allowSorting={false} />
                    <Column dataField="planId" caption="Plan" allowSorting={false} />
                    <Column dataField="quantity" caption="Quantity" calculateDisplayValue={(x) => x.quantity ?? 'N/A'} allowSorting={false} />
                    <Column dataField="statusLabel" caption='Status' allowSorting={false} />
                    <Column cellRender={renderActionsCell} allowSorting={false} />
                </DataGrid>
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [subscriptions]
    )

    return (
        <>
            <div className="my_subscriptions__wrapper__table-title">Subscriptions</div>
            <div className="my_subscriptions__wrapper__table-subtitle">A list of all your purchased subscriptions</div>
            {table}
        </>
    )
}

export default mySubscriptionsTable;