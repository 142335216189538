import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '../../../components/dialog/dialog';
import Spinner, { ClipSpinner } from '../../../components/spinner';
import { SubscriptionAuditLogDto, SubscriptionClient, SubscriptionDto } from "../../../services/service";
import ActivityLogDialogTable from './activityLogDialogTable';

interface ViewActivityLogProps {
    closeDialog(): void;
    isOpen: boolean;
    subscription: SubscriptionDto
}

const activityLogDialog = ({ closeDialog, isOpen, subscription }: ViewActivityLogProps) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SubscriptionAuditLogDto[] | undefined>();

    const fetchData = useCallback(
        async () => {
            setLoading(true);
            const client = new SubscriptionClient();
            const result = await client.getSubscriptionLogs(subscription.subscriptionId);
            setData(result || []);

            setLoading(false);
        },
        [setLoading, setData, subscription]
    )

    useEffect(
        () => {
            if (isOpen && !data) {
                fetchData();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isOpen]
    )

    return (
        <Dialog
            title={`Activity Log`}
            isOpen={isOpen}
            onClose={closeDialog}
        >
            <div className="activity_log_dialog">
                {loading ?
                    <Spinner text='Loading...' style={{ height: 200 }}>
                        <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                    </Spinner> : <ActivityLogDialogTable logs={data!} />}
            </div>
        </Dialog>
    )
}

export default activityLogDialog;