// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consent-step{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}
.consent-step__title{max-width:300px;font-weight:700;font-size:18px;font-family:var(--secondary-font);line-height:21px;text-align:center}
.consent-step__ms-logo{height:0.75rem;width:0.75rem}
.consent-step__button{margin-top:64px;border-radius:32px;padding-top:0.5rem;padding-bottom:0.5rem;padding-left:27px;padding-right:27px;font-size:1.125rem;line-height:1.5rem}
.consent-step__footer{margin-top:16px;text-align:center}
.consent-step__link{margin-top:32px;font-weight:400;text-decoration:underline}
.consent-step__error{margin-top:16px;color:var(--red);font-weight:700}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
