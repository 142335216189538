// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite_user__dialog{min-height:350px}
.invite_user__loading{height:209px}
.invite_user__error{color:#df3705;font-weight:500}
.invite_user__success{color:#008f4c;font-weight:500}
.invite_user__dialog-footer{display:flex;align-items:center;justify-content:flex-end;width:100%}
.role_section{padding:10px 0}
.role_section__value{width:40%;padding:10px}
.email_section{padding:10px 0}
.email_section__value{padding:10px}
.save-button{width:150px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
