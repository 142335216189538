// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit_user__form{padding:32px 64px}
.edit_user__footer{display:flex;flex-direction:row;padding-top:30px}
.edit_user__title{margin-bottom:20px;color:var(--electric-indigo);font-weight:500;font-size:20px}
.edit-user-detail{display:flex;flex-direction:column;min-width:300px;min-height:30px;margin-bottom:20px;text-decoration:none}
.edit-user-detail__label{margin-bottom:14px;font-weight:bold;font-size:14px;font-family:var(--secondary-font)}
.edit-user-detail__value{max-width:300px}
.edit_user__error{color:#df3705;font-weight:500}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
