// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activation-completed__title{max-width:300px;padding-bottom:40px;font-weight:700;font-size:18px;font-family:var(--secondary-font);line-height:21px;text-align:center}
.activation-completed__buttons-container{padding-top:30px}
.activation-completed__button{width:170px;margin-right:auto;margin-left:auto}
.activation-completed__img{margin:auto}
.manage-subscription-btn{margin-top:10px;color:var(--electric-indigo);background-color:var(--white) !important;border-color:var(--white) !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
