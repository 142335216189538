import {
    AuthenticationResult,
    AuthError,
    BrowserCacheLocation,
    Configuration,
    EventMessage,
    EventType,
    InteractionRequiredAuthError,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication
} from '@azure/msal-browser';
import ApplicationInsights from '../common/applicationInsights';
import settings from '../settings/settings';
import { DIRECTORY_ACCESS_AS_USER_SCOPE, LOGIN_SCOPES, RedirectResponseState } from './constants';

class ClientApplicationSingleton {
    private _configurationGetter: () => Configuration;
    private _useSso: boolean;
    constructor(configurationGetter: () => Configuration, useSso: boolean) {
        this._configurationGetter = configurationGetter;
        this._useSso = useSso;
    }

    private _clientApplication: IPublicClientApplication | undefined;

    get instance() {
        if (!this._clientApplication) {
            throw new Error('CALL INITIALIZE FUNCTION BEFORE THIS');
        }

        return this._clientApplication;
    }

    public lastState: RedirectResponseState = RedirectResponseState.None;
    public redirectRequestState: 'loading' | 'no-redirect' | 'redirect-successful' | 'redirect-error' = 'loading';

    public initialize = async () => {
        const config: Configuration = this._configurationGetter();
        config.system = {
            ...config.system,
            loggerOptions: {
                ...config.system?.loggerOptions,
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            if (settings.Production) {
                                ApplicationInsights.trackExceptionMessage(message)
                            } else {
                                console.error(message, `ClientId: ${config.auth.clientId}`);
                            }
                            return;
                        case LogLevel.Info:
                            if (settings.Production) {
                                return;
                            }
                            console.info(message, `ClientId: ${config.auth.clientId}`);
                            return;
                        case LogLevel.Verbose:
                            if (settings.Production) {
                                return;
                            }
                            console.debug(message, `ClientId: ${config.auth.clientId}`);
                            return;
                        case LogLevel.Warning:
                            if (settings.Production) {
                                return;
                            }
                            console.warn(message, `ClientId: ${config.auth.clientId}`);
                            return;
                    }
                }
            }
        };

        this._clientApplication = new PublicClientApplication(config);

        let tokenResponse: AuthenticationResult | null = null;

        try {
            tokenResponse = await this._clientApplication.handleRedirectPromise();
            if (tokenResponse) {
                // If tokenResponse is truthy, your app returned from a redirect operation,
                // and it completed successfully
                this._clientApplication.setActiveAccount(tokenResponse.account);
                this.redirectRequestState = 'redirect-successful';
                this.lastState = (tokenResponse.state as RedirectResponseState) ?? RedirectResponseState.None;
            } else {
                // If .then was called but tokenResponse is falsey, that means your app is not returning
                // from a redirect operation (e.g. user visiting the site for the first time)
                this.redirectRequestState = 'no-redirect';
            }
        } catch (e) {
            console.error(e);
            this.redirectRequestState = 'redirect-error';
        }

        await this._clientApplication.initialize();

        if (this._useSso) {
            try {
                // Try to Single Sign On if user already signed in our B2C tenant.
                tokenResponse = await this._clientApplication.ssoSilent({ scopes: LOGIN_SCOPES() });
                this._clientApplication.setActiveAccount(tokenResponse.account);
            } catch (e) {
                // No SSO available
            }
        }

        this._clientApplication.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                this._clientApplication!.setActiveAccount(account);
            }

            if (
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
                event.payload &&
                (event.payload as AuthenticationResult).scopes.includes(DIRECTORY_ACCESS_AS_USER_SCOPE)
            ) {
                const payload = event.payload as AuthenticationResult;
                this._clientApplication!.setActiveAccount(payload.account);
            }
        });
    };

    public shouldForceUiInteraction(error: AuthError) {
        return error instanceof InteractionRequiredAuthError || error.errorCode === 'state_mismatch';
    }

    public logout = async () => {
        const redirectUri = window.location.origin;
        try {
            await this._clientApplication?.logoutRedirect({ postLogoutRedirectUri: redirectUri });
        } catch (e) {
            console.error(e);
            localStorage.clear();
            window.location.href = redirectUri;
        }
    };
}

export const ClientApplication = new ClientApplicationSingleton(() => {
    return {
        auth: {
            clientId: settings.AdB2CClientId!,
            redirectUri: `${window.location.origin}`,
            navigateToLoginRequestUrl: false,
            authority: settings.Authority,
            knownAuthorities: settings.KnownAuthorities
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
        }
    };
}, true);

export const BootstrapApplication = new ClientApplicationSingleton(() => {
    return {
        auth: {
            clientId: settings.BootstrapClientId!,
            redirectUri: `${window.location.origin}`,
            navigateToLoginRequestUrl: false
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false
        }
    };
}, false);
