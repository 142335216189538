// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.existing-subscription{height:100%}
.existing-subscription .wrapper{display:flex;flex-direction:column;align-items:flex-start;justify-content:space-between;width:100%;height:100%;padding:32px 64px 10px}
.existing-subscription__title{color:var(--electric-indigo);font-weight:700;font-size:18px;font-family:var(--secondary-font);line-height:21px}
.existing-subscription__body{display:flex;flex-direction:row;margin-top:20px;margin-bottom:40px}
.existing-subscription .plan-details{margin-left:40px}
.existing-subscription .plan-details:first-of-type{margin-left:0}
.existing-subscription .plan-details-detail{margin-bottom:20px}
.existing-subscription .plan-details-detail:last-of-type{margin-bottom:0}
.existing-subscription .plan-details-detail__title{font-weight:600;font-size:14px;line-height:19px}
.existing-subscription .plan-details-detail__value{font-size:12px;line-height:19px}
.existing-subscription .manage-subscription-button{margin-bottom:60px}
.existing-subscription .footer{display:flex;flex-direction:column;align-items:center;align-self:center;justify-content:center;width:70%}
.existing-subscription .footer hr{width:100%;border-top:1px solid #cacaca}
.existing-subscription .footer a{color:var(--electric-indigo)}
.existing-subscription .audit-log-period-banner{display:flex;flex-direction:row;align-items:center;justify-content:space-around;width:55%;margin-bottom:32px;padding:8px;padding-right:24px;padding-left:16px;background:var(--barely-cyber-40) 0% 0% no-repeat padding-box;border-radius:18px}
.existing-subscription .audit-log-period-banner .text-message{margin-right:16px;color:#333350;font-style:italic;text-align:left}
.existing-subscription .audit-log-period-banner .info-icon{margin-right:16px;margin-left:4px;padding-right:16px;color:var(--dim-gray);border-right:1px solid var(--light-gray-50);opacity:1}
.existing-subscription .audit-log-period-banner .info-icon-content{fill:#66667c}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
