import { TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { APP_URLS } from '../../../appUrls';
import { EMAIL_REGEX } from '../../../assets/strings/regex';
import Button from '../../../components/button/button';
import Dialog from '../../../components/dialog/dialog';
import Loading from '../../../components/loading/loading';
import { OuterLink } from '../../../components/outerLink/outerLink';
import UserRoleDropdown from '../../../components/userRoleDropdown/userRoleDropdown';
import userRolesList, { UserRoleItem } from '../../../components/userRoleDropdown/userRoles';
import { AddUserToSubscriptionResultDtoEnum, SysKitSubscriptionsClient } from '../../../services/service';
import './inviteUser.scss';

const STRINGS = {
    TITLE: 'Invite User',
    CLOSE_BUTTON: 'Close',
    SAVE_BUTTON: 'Invite User',
    LEARN_MORE: 'Learn more',
    ERROR_MESSAGE: 'Failed to invite user.',
    SUCCESS_USER_ADDED: 'The user was added successfully to the subscription.',
    SUCCESS_USER_INVITED: 'User invitation successfully sent.',
    INFO: 'User will have to accept the invitation via e-mail and create an account.',
    EMAIL_INPUT: {
        PLACEHOLDER: 'Enter user e-mail'
    },
    ROLE_INPUT: {
        LABEL: 'Select user role'
    }
};

interface InviteUserDialogProps {
    subscriptionId: string;
    closeDialog(): void;
    isOpen: boolean;
    onSuccess?: () => void;
    initialValue?: string;
}

const regex = new RegExp(EMAIL_REGEX);

const InviteUserDialog: React.FC<InviteUserDialogProps> = props => {
    const { subscriptionId, closeDialog, isOpen, onSuccess, initialValue } = props;

    const [email, setEmail] = useState<string>();
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [roleInfo, setRoleInfo] = useState<string>();
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [successState, setSuccessState] = useState<AddUserToSubscriptionResultDtoEnum>(AddUserToSubscriptionResultDtoEnum.UserAddedToSubscription);

    useEffect(() => {
        setIsEmailValid(true);
        setEmail(initialValue);
        setIsSuccess(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    //#region Actions

    const onEmailValueChanged = e => {
        setIsEmailValid(e.value && regex.test(e.value));
        setEmail(e.value);
    };

    const onSaveButtonClicked = async () => {
        setLoading(true);

        try {
            const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
            const result = await syskitSubscriptionsClient.inviteUser(subscriptionId, {
                email: email!,
            });

            setIsSuccess(true);
            setSuccessState(result);
        } catch (error) {
            console.error(error);
            setHasErrors(true);
            setErrorMessage(STRINGS.ERROR_MESSAGE);
        }
        finally {
            setLoading(false);
        }
    };

    const onUserRoleChanged = (selectedRole: UserRoleItem) => {
        setRoleInfo(selectedRole.info);
    };

    //#endregion Actions

    //#region Rendering

    const renderFooter = () => {
        const saveButtonCallback = isSuccess ? onSuccess : onSaveButtonClicked;
        const closeDialogCallback = isSuccess ? onSuccess : closeDialog;
        return (
            <div className="invite_user__dialog-footer">
                {hasErrors && <div className="invite_user__error">{errorMessage}</div>}
                {isSuccess && <div className="invite_user__success">
                    {successState === AddUserToSubscriptionResultDtoEnum.UserAddedToSubscription && STRINGS.SUCCESS_USER_ADDED}
                    {successState === AddUserToSubscriptionResultDtoEnum.UserInvitedToSubscription && STRINGS.SUCCESS_USER_INVITED}
                </div>}
                <Button
                    onClick={closeDialogCallback}
                    textual
                >{STRINGS.CLOSE_BUTTON}</Button>
                <Button
                    className="save-button"
                    onClick={saveButtonCallback}
                    disabled={(!email || !isEmailValid) || isSuccess}
                >{STRINGS.SAVE_BUTTON}</Button>
            </div>
        );
    };

    //#endregion Rendering

    const showLoader = loading;
    const showContent = !showLoader;

    return (
        <div>
            <Dialog
                title={STRINGS.TITLE}
                isOpen={isOpen}
                onClose={closeDialog}
                footer={renderFooter()}
                className="invite_user__dialog"
            >
                {showLoader && <div className="invite_user__loading"><Loading /></div>}
                {showContent &&
                    <div>
                        <div className="role_section">
                            <div className="role_section__label">{STRINGS.ROLE_INPUT.LABEL}</div>
                            <div className="role_section__value">
                                <UserRoleDropdown
                                    item={userRolesList.find(i => i.key === 3)}
                                    onChange={onUserRoleChanged} />
                            </div>
                            {!!roleInfo && <div>{roleInfo} <OuterLink href={APP_URLS.inviteUserLearnMore} icon >{STRINGS.LEARN_MORE}</OuterLink></div>}
                        </div>
                        <div className="email_section">
                            <div className="email_section__value">
                                <TextBox
                                    placeholder={STRINGS.EMAIL_INPUT.PLACEHOLDER}
                                    value={email}
                                    valueChangeEvent="keyup"
                                    onValueChanged={onEmailValueChanged}
                                    isValid={isEmailValid}
                                    defaultValue={initialValue}>

                                </TextBox>
                            </div>
                            <div className="email_section__info">{STRINGS.INFO}</div>
                        </div>
                    </div>
                }
            </Dialog>
        </div>
    );
};


export default InviteUserDialog;