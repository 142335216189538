import React, { useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import POINT_LOGO from '../../../assets/images/point-logo.svg';
import Button from "../../../components/button/button";
import { useWizard } from '../../../components/wizard/wizardContext';
import { LOGIN_SCOPES, RedirectResponseState } from '../../../msal/constants';

import './signInStep.scss';

export const SignInStep: React.FC = () => {
    const { nextStep } = useWizard();
    const { instance } = useMsal();

    useEffect(() => {
        if (instance.getActiveAccount()) {
            nextStep();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSignIn = () =>
        instance.loginRedirect({
            scopes: [...LOGIN_SCOPES()],
            prompt: 'select_account',
            state: RedirectResponseState.SignIn
        })

    return (
        <div className="sign-in-step">
            <img className="sign-in-step__logo" src={POINT_LOGO} />
            <Button className="sign-in-step__button" onClick={onSignIn}>Sign in</Button>
            <div className="sign-in-step__footer">You will be redirected to the sign in page</div>
        </div>
    );
}