import classNames from 'classnames';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ROUTES from '../../routes/routes';

//#region Interfaces
export interface PageScrollContainerProps {
    children: React.ReactNode;
}

//#endregion Interfaces

const PageScrollContainer: React.FunctionComponent<PageScrollContainerProps> = props => {
    const matchActivateSubscriptionWizard = useRouteMatch(ROUTES.ACTIVATE_SUBSCRIPTION_FORM.ROUTE);
    return (
        <div
            className={classNames('scroll-container', {
                'tw-relative': !!matchActivateSubscriptionWizard
            })}
        >
            {props.children}
        </div>
    );
};
export default PageScrollContainer;
