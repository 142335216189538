import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from '../../routes/routes';
import FulfillmentActivationResult from './fulfillmentActivationResult';
import SubscriptionDetails from './subscriptionDetails';
import SubscriptionResolverProvider from './subscriptionResolverContext';

const subscriptionResolver = () => {
    return (
        <SubscriptionResolverProvider>
            <Switch>
                <Route exact path={ROUTES.SUBSCRIPTION_RESOLVER.ROUTE} component={SubscriptionDetails} />
                <Route exact path={ROUTES.SUBSCRIPTION_RESOLVER_RESULT.ROUTE} component={FulfillmentActivationResult} />
            </Switch>
        </SubscriptionResolverProvider>
    )
}

export default subscriptionResolver;