import classNames from "classnames";
import React, { HTMLAttributes, PropsWithChildren } from "react";
import './tile.scss';

const tile = ({ children, ...rest }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
    const classname = classNames(rest.className, 'tile');
    return (
        <div {...rest} className={classname}>
            {children}
        </div>
    )
}

export default tile;