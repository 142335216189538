import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Spinner, { ClipSpinner } from '../../components/spinner';
import Tile from '../../components/tile/tile';
import { SubscriptionClient, SubscriptionDto } from '../../services/service';
import './mySubscriptions.scss';
import MySubscriptionsTable from './mySubscriptionsTable';
import NoSubscriptionsScreen from './noSubscriptionsScreen';

const mySubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState<undefined | SubscriptionDto[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchMySubscriptions = useCallback(
        async () => {
            setLoading(true);
            const client = new SubscriptionClient();
            const result = await client.getMySubscriptions();
            setSubscriptions(result);
            setLoading(false);
        },
        [setSubscriptions, setLoading]
    )

    useEffect(
        () => {
            fetchMySubscriptions();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const noData = (!subscriptions || !subscriptions.length);
    const classname = classNames('my_subscriptions__wrapper', {
        'no_data': noData
    });
    return (
        <div className="my_subscriptions">
            {loading ?
                <Spinner text='Loading...' style={{ height: 400 }}>
                    <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                </Spinner>
                :
                <Tile style={{ width: 'unset' }} className={classname}>
                    {noData
                        ? <NoSubscriptionsScreen />
                        : <MySubscriptionsTable subscriptions={subscriptions!} />}
                </Tile>}
        </div>
    )
}

export default mySubscriptions;