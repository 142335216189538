import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/button/button';
import { onExternalLinkClickCallback } from '../../../../../utils/url';
import { useSubscriptionContext } from "../../../../page/subscriptionContext/subscriptionContext";

import ACTIVATION_COMPLETED_IMG from '../../../../../assets/images/activation-completed.svg';

import './subscriptionActivationCompleted.scss';

const subscriptionActivationCompleted = () => {
    const { subscriptionData, setSubscriptionData } = useSubscriptionContext();
    const history = useHistory();

    const onManageSubscriptionClicked = () => {
        history.push(`/control-center/${subscriptionData?.subscriptionId}/subscriptions`);
    };
    const onOpenPointClicked = () => {
        if (subscriptionData && subscriptionData.pointInstanceUrl) {
            onExternalLinkClickCallback(subscriptionData.pointInstanceUrl);
        }
    }

    return (
        <div className='activation-completed'>
            <div className='activation-completed__title'>You're good to go!</div>
            <img className='activation-completed__img' src={ACTIVATION_COMPLETED_IMG}></img>
            <div className='activation-completed__buttons-container'>
                <Button className={classNames('activation-completed__button', 'open-syskit-point-btn')} onClick={onOpenPointClicked}>Open Syskit Point</Button>
                <Button className={classNames('activation-completed__button', 'manage-subscription-btn')} onClick={onManageSubscriptionClicked}>Manage Subscription</Button>
            </div>
        </div>
    );
}

export default subscriptionActivationCompleted;