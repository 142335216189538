import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { ClientApplication } from '../msal/clientApp';
import { getMarketingId } from './cookieUtils';
import TrackedEventProcessEnum from './trackedEventProcessEnum';
import TrackedEventTypeEnum from './trackedEventTypeEnum';

export class AppInsights {
    private appInsights: ApplicationInsights | undefined = undefined;

    public initialize = (connectionString: string | undefined) => {
        if (!connectionString) return;

        const browserHistory = createBrowserHistory();

        const reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory }
                }
            }
        });
        this.appInsights.loadAppInsights();
    };

    private getDefaultProperties = (additionalProperties?: { [key: string]: string }) => {

        const account = ClientApplication.instance.getActiveAccount();
        const userName: string =
            account?.idTokenClaims?.email != null ? (account?.idTokenClaims?.email as string) : 'N/A';
        const oId: string = account?.localAccountId ?? 'N/A';
        const tenantId: string = account?.tenantId != null ? account?.tenantId : 'N/A';
        const marketingId = getMarketingId();

        let properties: { [key: string]: string } = {
            MarketingId: marketingId ?? 'N/A',
            TenantId: tenantId,
            User: userName,
            Oid: oId,
            Session: appInsights.appInsights?.context.getSessionId() ?? 'N/A'
        };

        if (additionalProperties) {
            properties = { ...properties, ...additionalProperties };
        }

        return properties;
    }

    public trackEvent = (
        event: string,
        eventType: TrackedEventTypeEnum,
        eventProcess: TrackedEventProcessEnum,
        additionalProperties?: { [key: string]: string }
    ) => {
        if (!this.appInsights) {
            console.warn('No Application Insights instance found.');
            return;
        }

        let properties = this.getDefaultProperties(additionalProperties);
        properties = {
            ...properties,
            ...
            {
                EventProcess: eventProcess.toString(),
                EventType: eventType.toString(),
            }
        }

        const account = ClientApplication.instance.getActiveAccount();
        const userName: string = account?.idTokenClaims?.email != null ? (account?.idTokenClaims?.email as string) : 'N/A';
        this.appInsights.setAuthenticatedUserContext(userName, userName, true);
        this.appInsights.trackEvent({ name: event }, properties);
    };

    public trackException = (exception: Error, additionalMessage: string, additionalProperties?: { [key: string]: string }) => {
        if (!this.appInsights) {
            console.warn('No Application Insights instance found.');
            return;
        }

        const account = ClientApplication.instance.getActiveAccount();
        const userName: string = account?.idTokenClaims?.email != null ? (account?.idTokenClaims?.email as string) : 'N/A';
        const properties = this.getDefaultProperties(additionalProperties);
        properties['message'] = additionalMessage;

        this.appInsights.setAuthenticatedUserContext(userName, userName, true);
        this.appInsights.trackException({ exception }, properties);
    }

    public trackExceptionMessage = (message: string) => {
        if (!this.appInsights) {
            console.warn('No Application Insights instance found.');
            return;
        }

        const account = ClientApplication.instance.getActiveAccount();
        const userName: string = account?.idTokenClaims?.email != null ? (account?.idTokenClaims?.email as string) : 'N/A';

        this.appInsights.setAuthenticatedUserContext(userName, userName, true);
        this.appInsights.trackException({ exception: { message, error: null, lineNumber: 0, url: '', columnNumber: 0 } }, this.getDefaultProperties());
    }
}

const appInsights = new AppInsights();
export default appInsights;
