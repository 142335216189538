enum IconEnum {
    Cancel = 'svg-icon-close',
    Check = 'svg-icon-check',
    CloseWizard = 'svg-icon-closeWizard',
    ExternalLink = 'svg-icon-externalLink',
    User = 'svg-icon-o365User',
    Calendar = 'svg-icon-calendar',
    BadgeChecked = 'svg-icon-licensed',
    BadgeFailed = 'svg-icon-unlicensed',
    ValidCheck = 'svg-icon-circleCheck',
    AlertTriangle = 'svg-icon-alertTriangle',
    Dropdown = 'svg-icon-dropDown',
    Help = 'svg-icon-help',
    Copy = 'svg-icon-copy',
    Feedback = 'svg-icon-feedback',
    Info = 'svg-icon-info',
    Edit = 'svg-icon-edit',
    Delete = 'svg-icon-delete',
    Mail = 'svg-icon-mail',
}

export default IconEnum;
