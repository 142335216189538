import React from 'react';
import { ButtonProps } from '../../button/button';

export interface WizardStepProps {
    stepId: string;
    title: React.ReactNode;
    description?: React.ReactNode;
    helpUri?: string;
    helpDisplayText?: string;
    hideButton?: boolean;
    children?: React.ReactNode;
    disableNext?: boolean;
    buttonProps?: Omit<ButtonProps, 'onClick'>;
    onNext?(): void | Promise<void>;
}

export const WizardStep: React.FC<WizardStepProps> = ({ children }) => {
    return <div className="wizard-step">{children}</div>
}