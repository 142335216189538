import { SilentRequest } from "@azure/msal-browser";
import React, { useEffect, useState } from 'react';
import { ENTERPRISE_APP_LOGIN_SCOPES } from "../../../../msal/constants";
import { handleErrorResponse } from "../../../../services/problemDetails";
import { PointTenantUserRegistrationStateEnum, SubscriptionActivationProgressStatusEnum, TenantSubscriptionDto } from '../../../../services/service';
import { useSubscriptionContext } from "../../../page/subscriptionContext/subscriptionContext";
import SubscriptionActivationCompleted from './subscriptionActivationCompleted/subscriptionActivationCompleted';
import SubscriptionActivationProgress from './subscriptionConfigurationProgress/subscriptionConfigurationProgress';


import { useHistory } from "react-router-dom";
import Spinner, { ClipSpinner } from '../../../../components/spinner';
import { BootstrapApplication, ClientApplication } from '../../../../msal/clientApp';
import ROUTES from '../../../../routes/routes';
import BaseClient from "../../../../services/baseClient";
import { getLocalStorageKey } from "../../activateSubscriptionFormContext";
import './finishStep.scss';

export const FinishStep: React.FC = () => {
    const { subscriptionData } = useSubscriptionContext();
    const activationCompleted = subscriptionData?.activationProgressStatus === SubscriptionActivationProgressStatusEnum.Completed && subscriptionData?.areResourcesProvisioned;
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();

    const getTenantForUser =
        async () => {
            try {
                setLoading(true);

                const account = BootstrapApplication.instance.getActiveAccount();

                const tokenRequest: SilentRequest = {
                    scopes: [...ENTERPRISE_APP_LOGIN_SCOPES()],
                    account: account!,
                    forceRefresh: true
                };

                const tokenResponse = await BootstrapApplication.instance.acquireTokenSilent(tokenRequest);

                const response = await fetch(`${(new BaseClient()).getBaseUrl('', '')}/api/syskit-subscriptions/user-tenant-info`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${tokenResponse.accessToken}`
                    },
                    mode: 'cors',
                    credentials: 'omit',
                    method: 'GET'
                });

                if (!response.ok) {
                    console.error(response.statusText);
                    await handleErrorResponse(response);
                }

                const json = await response.text();
                const userTenantInfo: TenantSubscriptionDto = JSON.parse(json);

                if (userTenantInfo?.userRegistrationState === PointTenantUserRegistrationStateEnum.RegisteredByOtherUser) {
                    const b2cAccount = ClientApplication.instance.getActiveAccount();
                    const accountKey = getLocalStorageKey(b2cAccount!);
                    localStorage.removeItem(accountKey!);
                    history.push(ROUTES.SUBSCRIPTION_ALREADY_ACTIVATED.ROUTE)
                }

                setLoading(false);
            }
            catch (e) {
                console.error(e);
            }
            finally {
                setLoading(false);
            }
        }

    useEffect(
        () => {
            getTenantForUser();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <div className="finish-step">
            {loading ?
                <Spinner text='Loading...' style={{ height: 400 }}>
                    <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                </Spinner>
                :
                <span>
                    {!activationCompleted && <SubscriptionActivationProgress />}
                    {activationCompleted && <SubscriptionActivationCompleted />}
                </span>
            }
        </div>
    )
}
