function isAlphanumeric(char: string): boolean {
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if ((char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z') || (char >= '0' && char <= '9')) {
        return true;
    }
    return false;
}

export function isValidSubdomain(subdomain: string): boolean {
    if (!subdomain) {
        return false;
    }

    if (subdomain.length > 63) {
        return false;
    }
    if (!isAlphanumeric(subdomain[0]) || !isAlphanumeric(subdomain[subdomain.length - 1])) {
        return false;
    }
    for (let i = 1; i < subdomain.length - 1; i++) {
        if (!isAlphanumeric(subdomain[i]) && subdomain[i] !== '-') {
            return false;
        }
    }
    return true;
}
