import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from '../button/button';
import Icon, { IconEnum } from '../icon';
import classNames from 'classnames';
import './dialog.scss';


export interface DialogProps {
    isOpen: boolean;

    hideHeader?: boolean;
    hideIcon?: boolean;
    header?: React.ReactNode;
    title?: React.ReactNode;

    children: React.ReactNode;

    hideFooter?: boolean;
    footer?: React.ReactNode;

    className?: string;

    onClose(): void;
}

// https://stackoverflow.com/questions/13694626/generating-random-numbers-0-to-1-with-crypto-generatevalues
const generateRandomNumber = () => {
    const randomNumberArray = new Uint32Array(1);
    self.crypto.getRandomValues(randomNumberArray);
    return randomNumberArray[0] * Math.pow(2,-32);
}

export default ({ isOpen, children, title, hideHeader, hideIcon, header, hideFooter, footer, className, onClose }: DialogProps) => {
    return (
        <CSSTransition
            id={Math.round(generateRandomNumber() * 10000)}
            in={isOpen}
            timeout={150}
            unmountOnExit
            mountOnEnter
        >
            <div className='dialog-background'>
                <div className={classNames('dialog', className)}>
                    {(!hideHeader || !!header) && <div className='dialog-header'>
                        {!header ? <>
                            <div className='title'>
                                {title}
                            </div>
                            {!hideIcon && <Icon
                                iconName={IconEnum.Cancel}
                                onClick={onClose}
                                width={14}
                                height={14}
                            />}
                        </> : header}
                    </div>}
                    <div className='dialog-content scrollbar'>
                        {children}
                    </div>
                    {(!hideFooter || !!footer) && <div className='dialog-footer'>
                        {!footer ? <>
                            <Button
                                className={'close-button'}
                                onClick={onClose}
                            >Close</Button>
                        </> : footer}
                    </div>}
                </div>
            </div>
        </CSSTransition>
    );
};