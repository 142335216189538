import React from 'react';
import './pageLayout.scss';

const pageLayout = (props) => {
    return (
        <div className="page-layout">
            {props.children}
        </div>
    )
}

export default pageLayout;