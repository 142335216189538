import ProblemDetails from "./problemDetails";

const errorMessageOverrides: Record<string, string> = {
    'https://syskit.com/_app-links/subscriptions/business-rules/NoPreDeployedPointInstanceAvailable': 'Due to high demand we cannot create your instance now. Please try again later or try another region.'
}

const baseBusinessRuleTypeUrl = 'https://syskit.com/_app-links/subscriptions/business-rules/';
export const NoPreDeployedPointInstanceAvailableRuleType = 'https://syskit.com/_app-links/subscriptions/business-rules/NoPreDeployedPointInstanceAvailable';

export function isBusinessRuleException(problemDetails: ProblemDetails): boolean {
    return problemDetails.type.startsWith(baseBusinessRuleTypeUrl);
}

export function getBusinessRuleErrorMessage(problemDetails: ProblemDetails): string {
    return errorMessageOverrides[problemDetails.type] ?? problemDetails.detail;
}