import { createContext, useContext } from "react";
import { noop } from "../../utils/noop";

interface WizardContextProps {
    step: number;
    setStep(stepId: string): void;
    nextStep(): void | Promise<void>;
}

export const defaultContextProps: WizardContextProps = {
    step: 0,
    setStep: noop,
    nextStep: noop
}

export const WizardContext = createContext<WizardContextProps>(defaultContextProps);

export function useWizard() {
    const context = useContext(WizardContext);

    if (context === undefined) {
        throw new Error("useWizard must be used inside a Wizard component");
    }

    return context;
}