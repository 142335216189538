// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-menu__dropdown{display:flex;flex-direction:column;width:100px}
.user-menu__option{padding:8px 24px;cursor:pointer}
.user-menu__option:hover{background-color:var(--white-5-dark)}
.user-menu__option:first-child{padding-top:16px}
.user-menu__option:last-child{padding-bottom:16px}
.user-menu__text{font-weight:600}
.user-menu__user-icon{margin-right:4px;fill:var(--eigengrau)}
.dx-popup-content{padding:0}
.svg-icon-dropDown{width:12px;height:12px;margin-left:2px;padding-top:2px;font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
