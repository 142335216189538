// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users__container{padding:30px}
.users__error{display:flex;align-items:center;justify-content:center;height:100%;color:#df3705}
.users__header{display:flex;justify-content:space-between;margin-bottom:15px}
.users__header h1{display:inline-block;margin:0;color:#5700af;font-weight:600;font-size:18px;font-family:var(--secondary-font)}
.users__grid .dx-datagrid-headers{background-color:#fff;border-bottom:0 !important}
.users__grid__action{margin:0 5px 0 20px}
.users__grid__action__disabled{fill:var(--light-gray-40)}
.users__tooltip{border-radius:8px !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
