import { Popup } from 'devextreme-react';
import React, { useState } from 'react';
import Icon, { IconEnum } from "../../../components/icon";
import { ClientApplication } from '../../../msal/clientApp';

import './userMenu.scss';

interface UserMenuProps {
    accountName?: string;
}

export const UserMenu: React.FC<UserMenuProps> = ({ accountName }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(curr => !curr)
    const handleHiding = () => setIsMenuOpen(false);

    const onSignOut = async () => await ClientApplication.logout();

    return <>
        <div id="user-menu" className="page-header__user-menu" onClick={toggleMenu}>
            <Icon iconName={IconEnum.User} className="user-menu__user-icon" />
            <span className="user-menu__text">{accountName}</span>
            <Icon iconName={IconEnum.Dropdown} />
        </div>
        <Popup
            className="user-menu__overlay"
            visible={isMenuOpen}
            position={{
                of: "#user-menu",
                collision: 'fit',
                my: { x: 'center', y: 'top' },
                at: { x: 'center', y: 'bottom' },
                offset: { y: 16 }
            }}
            width="auto"
            height="auto"
            showTitle={false}
            shading={false}
            dragEnabled={false}
            focusStateEnabled={false}
            closeOnOutsideClick
            onHiding={handleHiding}
            animation={{
                show: { type: 'fade', duration: 0, from: 0, to: 1 },
                hide: { type: 'fade', duration: 400, from: 1, to: 0 }
            }}>
            <div className="user-menu__dropdown">
                <div className="user-menu__option" onClick={onSignOut}>Sign out</div>
            </div>
        </Popup>
    </>
}