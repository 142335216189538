import classNames from 'classnames';
import React from 'react';
import { OuterLink } from '../../outerLink/outerLink';
import { WizardStepProps } from '../step/wizardStep';
import { useWizard } from '../wizardContext';

import './wizardNavigation.scss';

interface WizardNavigationProps {
    className?: string;
    title?: string;
    steps: Array<WizardStepProps>;
}

interface WizardNavigationStepProps {
    className?: string;
    classNameChain?: string;
    isCurrent?: boolean;
    title: React.ReactNode;
    description?: React.ReactNode;
    helpUri?: string;
    helpDisplayText?: string;

    first?: boolean;
    last?: boolean;
}

const WizardNavigationStep: React.FC<WizardNavigationStepProps> = ({ className, classNameChain, isCurrent, title, description, helpUri, helpDisplayText, first, last }) => (
    <>
        <div className={classNames(
            "wizard-navigation-step__chain",
            classNameChain,
            {
                "wizard-navigation-step__chain--current": isCurrent
            }
        )}>
            <div
                className={classNames(
                    "wizard-navigation-step__line",
                    "wizard-navigation-step__line--before",
                    {
                        hide: first
                    })}
            />
            <div className={classNames("wizard-navigation-step__dot", {
                // "wizard-navigation-step__dot--current": isCurrent
            })} />
            <div
                className={classNames(
                    "wizard-navigation-step__line",
                    "wizard-navigation-step__line--after",
                    {
                        hide: last
                    })}
            />
        </div>
        <div
            className={classNames(
                "wizard-navigation-step",
                className,
                {
                    "wizard-navigation-step--current": isCurrent
                })}
        >
            <div className="wizard-navigation-step__title">{title}</div>
            <div className="wizard-navigation-step__description">{description}</div>
            {isCurrent && helpUri !== undefined && <OuterLink href={helpUri} icon>{helpDisplayText ?? 'Why do I need to do this?'}</OuterLink>}
        </div>
    </>
)

export const WizardNavigation: React.FC<WizardNavigationProps> = ({ title, className, steps }) => {
    const { step: currentStep } = useWizard();

    return (
        <div className={classNames("wizard-navigation", className)}>
            <div className="wizard-navigation__title">{title}</div>
            <div className="wizard-navigation__steps">
                {steps.map((step, idx) => (
                    <WizardNavigationStep
                        key={step.stepId}
                        classNameChain={classNames({ done: currentStep > idx })}
                        title={step.title}
                        description={step.description}
                        helpUri={step.helpUri}
                        helpDisplayText={step.helpDisplayText}
                        isCurrent={currentStep === idx}
                        first={idx === 0}
                        last={idx === steps.length - 1}
                    />
                ))}
            </div>
        </div>
    )
}