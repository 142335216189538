import React from "react";

import './labelDetail.scss';

interface LabelDetailProps {
    label: string;
    value: React.ReactNode;
    description?: string;
    info?: string
}

const LabelDetail = ({ label, value, description, info }: LabelDetailProps) => {
    return (
        <div className="label_detail">
            <div className="label_detail__label">{label}</div>
            {description &&
                <div className="label_detail__description">{description}</div>
            }
            <div className="label_detail__value">{value}</div>
            {info &&
                <div className="label_detail__info">{info}</div>}
        </div>
    )
}

export default LabelDetail;