import classNames from 'classnames';
import React from 'react';
import IconEnum from './iconEnum';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
    iconName: IconEnum;
    testId?: string;
}

export const Icon: React.FC<IconProps> = ({ iconName, testId, ...svgProps }) => {

    const className = classNames(iconName, svgProps.className, 'icon', 'svg-icon', {
        clickable: !!svgProps.onClick,
    });

    return (
        <svg
            {...svgProps}
            className={className}
            data-testid={testId}
            height={svgProps.height ?? 16}
            width={svgProps.width ?? 16}
        >
            <use xlinkHref={`#symbol-defs_${iconName}`} />
        </svg>
    );
};

export default Icon;
