import React from 'react';
import { ErrorScreen } from './errorScreen';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    error?: Error;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {}
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        console.error(error);
        return { error }
    }

    render(): React.ReactNode {
        if (this.state.error) {
            return <ErrorScreen error={this.state.error} />
        }

        return this.props.children;
    }
}