import SelectBox from 'devextreme-react/select-box';
import React from 'react';
import { tooltipRender } from '../dxComponents/itemRenderers';
import './timeZoneDropdown.scss';
import timeZonesList, { TimeZoneItem } from './timeZones';

interface TimeZonesDropdown {
    item?: TimeZoneItem;
    onChange(item: any): void;
}

const TimeZoneDropdown = ({ onChange, item }: TimeZonesDropdown) => {
    const onValueChange = e => onChange(e.value!);

    return (
        <SelectBox
            dataSource={timeZonesList}
            displayExpr={"value"}
            valueExpr={"key"}
            searchMode="contains"
            searchEnabled={true}
            searchExpr="value"
            searchTimeout={200}
            value={item}
            hoverStateEnabled={false}
            onValueChanged={onValueChange}
            dropDownOptions={{ maxHeight: '40vh' }}
            className="time-zone-dropdown"
            itemRender={tooltipRender('value')}
        />
    )
}

export default TimeZoneDropdown;