import React from 'react';
import Tile from '../../components/tile/tile';
import ActivateSubscriptionButton from './components/activateSubscriptionButton';
import DetailsComponent from './components/detailsComponent';
import './subscriptionDetails.scss';
import { useSubscriptionResolverContext } from './subscriptionResolverContext';

const subscriptionDetails = () => {
    const { subscription } = useSubscriptionResolverContext();

    return(
        <div className={'landing_page'}>
            {!!subscription &&
                <>
                    <Tile>
                        <DetailsComponent subscription={subscription!} />
                    </Tile>
                    <ActivateSubscriptionButton />
                </>}
        </div>
    )
}

export default  subscriptionDetails;