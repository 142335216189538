import { CheckBox, TextBox } from "devextreme-react";
import React, { useEffect } from 'react';
import { APP_URLS } from "../../../appUrls";
import appInsights from '../../../common/applicationInsights';
import TrackedEventProcessEnum from '../../../common/trackedEventProcessEnum';
import TrackedEventTypeEnum from '../../../common/trackedEventTypeEnum';
import DataRegionDropdown from "../../../components/dataRegionDropdown/dataRegionDropdown";
import { IconEnum } from "../../../components/icon";
import Icon from '../../../components/icon/icon';
import LabelDetail from "../../../components/labelDetail/labelDetail";
import OuterLink from "../../../components/outerLink/outerLink";
import TimeZoneDropdown from "../../../components/timeZoneDropdown/timeZoneDropdown";
import { useWizard } from '../../../components/wizard/wizardContext';
import { useActivateSubscriptionFormContext } from "../activateSubscriptionFormContext";

import './instanceSetupStep.scss';

const CompanyName = () => {
    const { formData, setFormData, isNameValid } = useActivateSubscriptionFormContext();

    return (
        <div className="connect-trial-form__field">
            <TextBox
                value={formData.companyName}
                onValueChanged={(e) => setFormData(val => ({ ...val, companyName: e.value }))}
                placeholder="e.g. Contoso"
                validationError={{ message: isNameValid.validationErrorMsg }}
                isValid={isNameValid.isValid}
            />
            {formData.companyName && formData.companyName.length > 0 && isNameValid.isValid && <Icon iconName={IconEnum.ValidCheck} />}
        </div>
    )
}

const UrlField = () => {
    const { formData, setFormData, isUrlValid } = useActivateSubscriptionFormContext();

    return (
        <div className="connect-trial-form__field">
            <TextBox
                value={formData.url}
                onValueChanged={(e) => setFormData(val => ({ ...val, url: e.value }))}
                placeholder="e.g. contoso"
                validationError={{ message: isUrlValid.validationErrorMsg }}
                isValid={isUrlValid.isValid}
            />
            <span>.syskit365.com</span>
            {formData.url && formData.url.length > 0 && isUrlValid.isValid && <Icon iconName={IconEnum.ValidCheck} />}
        </div>
    )
}

const DataRegionField = () => {
    const { formData, setFormData } = useActivateSubscriptionFormContext();

    return (
        <DataRegionDropdown
            item={formData.dataRegion}
            onChange={e => setFormData(val => ({ ...val, dataRegion: e }))}
        />
    )
}

const TimeZoneField = () => {
    const { formData, setFormData } = useActivateSubscriptionFormContext();

    return (
        <TimeZoneDropdown
            item={formData.timeZone as any}
            onChange={e => setFormData(val => ({ ...val, timeZone: e }))}
        />
    )
}

const SlaAgreementCheckbox = () => {
    const { formData, setFormData } = useActivateSubscriptionFormContext();

    return (
        <div className="connect-trial-form__field connect-trial-form__checkbox">
            <CheckBox
                value={formData.didConsentToSla}
                onValueChanged={e => setFormData(val => ({ ...val, didConsentToSla: e.value! }))}
            />
            <span>I have read and agree with the <OuterLink href={APP_URLS.subscriptionAgreement}>Syskit End User Software License Agreement</OuterLink></span>
        </div>)
}

export const InstanceSetupStep: React.FC = () => {
    const { nextStep } = useWizard();

    useEffect(() => {
        appInsights.trackEvent("User opened the activate subscription form", TrackedEventTypeEnum.RegistrationFormOpened, TrackedEventProcessEnum.SubscriptionActivationProcess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="connect-trial-form">
            <LabelDetail label="Organization Name" value={<CompanyName />} />
            <LabelDetail label="URL" description="Input URL to get a customized domain for your organization" value={<UrlField />} />
            <LabelDetail label="Time Zone" description="Select preferred local time in which the data will be stored" value={<TimeZoneField />} />
            <LabelDetail label="Data Region" description="Select where would you like the data to be stored so that Syskit Point can be the closest to the majority of your users" value={<DataRegionField />} info="Hosted in Microsoft Azure Cloud" />
            <SlaAgreementCheckbox />
        </div>
    )
}