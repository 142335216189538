import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import Spinner, { ClipSpinner } from '../../components/spinner';
import ROUTES from '../../routes/routes';
import { SubscriptionOverviewDto, SysKitSubscriptionsClient } from '../../services/service';
import { ExistingSubscription } from '../billing/existingSubscription/existingSubscription';
import ControlCenter from '../controlCenter/controlCenter';
import { EditUserForm } from '../editUserForm/editUserForm';
import { Users } from '../users/users';
import './controlCenterNavigation.scss';
import { CONTROL_CENTER_NAVIGATION_ITEMS } from './controlCenterNavigationItems';

const controlCenterNavigation = () => {
    const [subscriptionOverview, setSubscriptionOverview] = useState<SubscriptionOverviewDto | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const { subscriptionId } = useParams() as any;
    const history = useHistory();

    const getSubscriptionOverview = useCallback(
        async () => {
            setLoading(true);

            try {
                const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
                const overview = await syskitSubscriptionsClient.getSubscriptionOverview(subscriptionId);
                setSubscriptionOverview(overview);
                setLoading(false);
            } catch (error) {
                if (error.status === 403) {
                    setLoading(false);
                    history.push(ROUTES.SUBSCRIPTION_ALREADY_ACTIVATED.ROUTE)
                }
                // Error handling goes here
                setLoading(false);
            }

        },
        [setSubscriptionOverview, subscriptionId, history]
    )

    useEffect(
        () => {
            getSubscriptionOverview();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const buildNavigationUrl = (itemHref: string) => {
        return ROUTES.CONTROL_CENTER.ROUTE.replace(':subscriptionId', subscriptionId) + '/' + itemHref;
    }

    return (
        <div className="control-center-navigation-container">
            <div className="control-center-navigation-container__navigation">
                <div className="control-center-navigation-container__category-title"></div>
                {CONTROL_CENTER_NAVIGATION_ITEMS.map((item, idx) => (
                    <React.Fragment key={idx}>
                        {item.type === 'category' &&
                            <div
                                key={idx}
                                className="control-center-navigation-container__category-title"
                            >
                                {item.text}
                            </div>}
                        {item.type === 'item' &&
                            <NavLink
                                key={idx}
                                to={buildNavigationUrl(item.href)}
                                className="control-center-navigation-container__category"
                                replace={true}
                                exact
                            >
                                {item.text}
                            </NavLink>}
                    </React.Fragment>
                ))}
            </div>
            {loading ?
                <Spinner text='Loading...' style={{ height: 400 }}>
                    <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                </Spinner>
                :
                <div className="tw-h-[430px] tw-min-h-[430px] tw-grow tw-bg-white">
                    <Switch>
                        <Route exact path={`${ROUTES.CONTROL_CENTER.ROUTE}/${ROUTES.CONTROL_CENTER.RELATIVE_LINKS.OVERVIEW}`}>
                            <ControlCenter subscriptionOverview={subscriptionOverview} />
                        </Route>
                        <Route path={`${ROUTES.CONTROL_CENTER.ROUTE}/${ROUTES.CONTROL_CENTER.RELATIVE_LINKS.SUBSCRIPTIONS}`}>
                            <ExistingSubscription subscriptionOverview={subscriptionOverview} />
                        </Route>
                        <Route path={`${ROUTES.CONTROL_CENTER.ROUTE}/${ROUTES.CONTROL_CENTER.RELATIVE_LINKS.USERS}`}>
                            <Users />
                        </Route>
                        <Route path={`${ROUTES.CONTROL_CENTER.ROUTE}/${ROUTES.CONTROL_CENTER.RELATIVE_LINKS.USER_EDIT}`}>
                            <EditUserForm />
                        </Route>
                    </Switch>
                </div>
            }
        </div>
    );
}

export default controlCenterNavigation;