import { AuthenticationResult } from '@azure/msal-browser';
import { BootstrapApplication } from '../../../msal/clientApp';
import { BASE_LOGIN_SCOPES, DIRECTORY_ACCESS_AS_USER_SCOPE, RedirectResponseState } from '../../../msal/constants';
import ROUTES from '../../../routes/routes';
import BaseClient from '../../../services/baseClient';
import { GlobalAdminCheckDto } from '../../../services/service';
import { acquireTokenSilentWithRetry } from '../activation.helpers';

export async function assertHasPermissions() {
    const instance = BootstrapApplication.instance;
    const account = instance.getActiveAccount()!;

    if (account === null) {
        return undefined;
    }

    return instance.acquireTokenSilent({
        account,
        scopes: [DIRECTORY_ACCESS_AS_USER_SCOPE]
    });
}

export async function getAdditionalScopes() {
    const instance = BootstrapApplication.instance;
    const account = instance.getActiveAccount()!;

    await instance.acquireTokenRedirect({
        account,
        scopes: [...BASE_LOGIN_SCOPES],
        extraScopesToConsent: [DIRECTORY_ACCESS_AS_USER_SCOPE],
        redirectUri: `${window.location.origin + ROUTES.ACTIVATE_SUBSCRIPTION_FORM.ROUTE}`,
        state: RedirectResponseState.GetAdditionalScopes
    });
}

export function hasDirectoryAccess(authResult: AuthenticationResult) {
    return authResult.scopes.indexOf(DIRECTORY_ACCESS_AS_USER_SCOPE) !== -1;
}

export async function checkGlobalAdminAccess() {
    const tokenResponse = await acquireTokenSilentWithRetry();

    if (!tokenResponse) {
        return undefined;
    }

    const response = await fetch(
        `${new BaseClient().getBaseUrl('', '')}/api/syskit-subscriptions/check-if-global-admin`,
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${tokenResponse.accessToken}`
            },
            mode: 'cors',
            credentials: 'omit',
            method: 'GET'
        }
    );

    return (await response.json()) as GlobalAdminCheckDto;
}
