import { AccountInfo } from '@azure/msal-browser';
import React, { useEffect, useMemo, useState } from 'react';
import { getMarketingId } from "../../common/cookieUtils";
import { ClientApplication } from '../../msal/clientApp';
import { RegionEnum, ValidationsClient } from '../../services/service';
import { isValidSubdomain } from './validation';

export interface ActivateSubscriptionFormData {
    companyName?: string;
    url?: string;
    timeZone?: string;
    dataRegion?: RegionEnum;
    didConsentToSla?: boolean;
    octopusTenantId?: string;
    requestedAdditionalScopes?: boolean;
}

export interface FieldValidation {
    isValid: boolean;
    validationErrorMsg?: string;
}

interface ActivateSubscriptionFormContextValues {
    formData: ActivateSubscriptionFormData;
    setFormData: React.Dispatch<React.SetStateAction<ActivateSubscriptionFormData>>;
    isFormValid: boolean;
    isUrlValid: FieldValidation;
    isNameValid: FieldValidation;
    marketingId: string;
}

const enum UrlParamsEnum {
    InstanceId = 'instanceid'
}

const connectTrialContext = React.createContext<ActivateSubscriptionFormContextValues | undefined>(undefined);

export const useActivateSubscriptionFormContext = () => {
    const context = React.useContext(connectTrialContext);
    if (context === undefined) {
        throw new Error();
    }

    return context;
}

export const getLocalStorageKey = (account: AccountInfo) => `${account.tenantId}_activate-subscription-form`

const ActivateSubscriptionFormContextProvider = ({ children }: React.PropsWithChildren) => {
    const [formData, setFormData] = useState<ActivateSubscriptionFormData>(() => {
        const defaultInitials: ActivateSubscriptionFormData = {
            url: '',
            didConsentToSla: false,
            requestedAdditionalScopes: false
        };

        const account = ClientApplication.instance.getActiveAccount();
        if (!account) {
            return defaultInitials;
        }

        // data is stored into local storage before requesting additional scopes
        const stringifiedData = localStorage.getItem(getLocalStorageKey(account));
        if (!stringifiedData?.length) {
            return defaultInitials;
        }

        try {
            return JSON.parse(stringifiedData) as ActivateSubscriptionFormData;
        }
        catch {
            return defaultInitials;
        }
    });

    const [marketingId] = useState<string | undefined>(() => getMarketingId());

    const [isUrlValid, setIsUrlValid] = useState<FieldValidation>({ isValid: true })
    const [isNameValid, setIsNameValid] = useState<FieldValidation>({ isValid: true })

    useEffect(
        () => {
            const checkIsUrlUnique = async () => {
                try {
                    const validationsClient = new ValidationsClient();
                    const isCustomUrlValid = await validationsClient.validateSubdomain({ subdomain: formData.url! });

                    if (!isCustomUrlValid) {
                        setIsUrlValid({
                            isValid: false,
                            validationErrorMsg: 'Url already taken'
                        });
                    }
                    else {
                        setIsUrlValid({
                            isValid: true,
                        });
                    }
                }
                catch (err) {
                    console.error(err);

                    setIsUrlValid({
                        isValid: false,
                        validationErrorMsg: 'Error occurred while validating url'
                    })
                }
            }

            if (!!formData.url?.length) {
                if (!isValidSubdomain(formData.url)) {
                    setIsUrlValid({
                        isValid: false,
                        validationErrorMsg: 'Only alphanumeric characters and hyphens are allowed.'
                    })
                } else {
                    checkIsUrlUnique();
                }
            }
            else if (!formData.url?.length && !isUrlValid.isValid) {
                setIsUrlValid({
                    isValid: true,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData.url]
    )

    useEffect(
        () => {
            const checkIsNameUnique = async () => {
                try {
                    const validationsClient = new ValidationsClient();
                    const isCustomNameValid = await validationsClient.validateInstanceName({ name: formData.companyName });

                    if (!isCustomNameValid) {
                        setIsNameValid({
                            isValid: false,
                            validationErrorMsg: 'Name already taken'
                        });
                    }
                    else {
                        setIsNameValid({
                            isValid: true,
                        });
                    }
                }
                catch (err) {
                    console.error(err);

                    setIsNameValid({
                        isValid: false,
                        validationErrorMsg: 'Error occurred while validating name'
                    })
                }
            }

            if (!!formData.companyName?.length) {
                if (formData.companyName.indexOf('/') !== -1 || formData.companyName.indexOf('\\') !== -1) {
                    setIsNameValid({
                        isValid: false,
                        validationErrorMsg: 'Invalid character detected. Please remove \\ and/or /.'
                    });
                    return;
                }
                checkIsNameUnique();
            }
            else if (!formData.companyName?.length && !isNameValid.isValid) {
                setIsNameValid({
                    isValid: true,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData.companyName]
    )

    useEffect(
        () => {
            const urlParams = new URLSearchParams(location.search);
            const instanceId = urlParams.get(UrlParamsEnum.InstanceId);
            if (instanceId) {
                setFormData(val => ({ ...val, octopusTenantId: instanceId }));
            }
        },
        [formData.octopusTenantId]
    )

    const isFormValid = useMemo(
        () => (
            !!formData.companyName?.length &&
            !!formData.url?.length &&
            formData.dataRegion !== undefined &&
            !!formData.timeZone &&
            isUrlValid.isValid &&
            isNameValid.isValid &&
            formData.didConsentToSla
        ),
        [formData, isNameValid.isValid, isUrlValid.isValid]
    )

    const values = React.useMemo(
        () => {
            return {
                formData,
                setFormData,
                isFormValid,
                isUrlValid,
                isNameValid,
                marketingId
            } as ActivateSubscriptionFormContextValues;
        },
        [formData, isFormValid, isNameValid, isUrlValid, marketingId]
    )
    return (
        <connectTrialContext.Provider value={values}>
            {children}
        </connectTrialContext.Provider>
    )
};

export default ActivateSubscriptionFormContextProvider;