import { useCallback, useReducer, useRef } from 'react';

type AsyncOperationState<TData> = {
    id?: number;
    data?: TData;
    error?: Error;
    isLoading: boolean;
};

type FetchHookActions<TData> =
    | { type: 'start'; id: number }
    | { type: 'end'; data: TData; id: number }
    | { type: 'error'; error: Error; id: number };

export function useAsync<TData>(initialState: AsyncOperationState<TData> = { isLoading: true, id: 0 }, keepData = false) {
    const lastRequestId = useRef(0);
    const reducer = (state: AsyncOperationState<TData>, action: FetchHookActions<TData>): AsyncOperationState<TData> => {
        switch (action.type) {
            case 'start':
                return { ...state, id: action.id, isLoading: true, data: keepData ? state.data : undefined };
            case 'end': {
                // protection if we get 2 requests, and they don't come in correct order
                if (action.id !== state.id) {
                    return state;
                }

                return { ...state, isLoading: false, data: action.data, id: action.id };
            }
            case 'error': {
                // protection if we get 2 requests, and they don't come in correct order
                if (action.id !== state.id) {
                    return state;
                }

                return { ...state, isLoading: false, error: action.error, id: action.id };
            }
            default:
                return state;
        }
    };

    const [loadData, dispatch] = useReducer(reducer, initialState);

    const executeAsync = useCallback(async (delegate: () => Promise<TData>) => {
        const requestId = ++lastRequestId.current;
        try {
            dispatch({ type: 'start', id: requestId });
            const data = await delegate();
            dispatch({ type: 'end', data, id: requestId });
        } catch (e) {
            dispatch({ type: 'error', error: e, id: requestId });
        }
    }, []);

    return { ...loadData, executeAsync };
}
