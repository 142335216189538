export interface UserRoleItem {
    key: number,
    value: string,
    info: string
}

const userRolesList: Array<UserRoleItem> = [
    { key: 3, value: "Administrator", info: "This role can see and manage almost everything." }
];

export default userRolesList;