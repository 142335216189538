import { CustomerDto, PriceDto, ProductDto, SupportedBillingIntervalDtoEnum } from "../../services/service";

export const billingIntervalOptions: BillingIntervalOption[] = [
    {
        interval: SupportedBillingIntervalDtoEnum.Monthly,
        key: "month",
        name: "Monthly"
    },
    {
        interval: SupportedBillingIntervalDtoEnum.Annually,
        key: "year",
        name: "Annually"
    }
]
export interface BillingReducerState {
    plans?: { [id: string]: ProductDto };
    isFetchingPlans: boolean;
    errorFetchingPlans?: string;

    customerInfo?: CustomerDto;
    isFetchingCustomerInfo: boolean;
    errorFetchingCustomerInfo?: string;

    pricing?: PriceDto;
    isFetchingPricing: boolean;
    errorFetchingPricing?: string;

    paymentLink?: string;
    isFetchingPaymentLink: boolean,
    errorFetchingPaymentLink?: string,
    isPaymentLinkCopiedToClipboard: boolean,

    selectedPlan?: ProductDto;
    numberOfUsers: number;
    currency: string;
    billingInterval: BillingIntervalOption;
    unitPrice?: number;
}

export const initialBillingState: BillingReducerState = {
    isFetchingPlans: true,
    isFetchingCustomerInfo: true,
    isFetchingPricing: false,
    isFetchingPaymentLink: false,
    numberOfUsers: 100,
    currency: "usd",
    billingInterval: billingIntervalOptions[1],
    isPaymentLinkCopiedToClipboard: false
};

export const billingReducer = (state: BillingReducerState, action: BillingAction): BillingReducerState => {
    if (action.type == "get-plans") {
        if (action.payload.isLoading) {
            return { ...state, isFetchingPlans: true }
        }

        if (action.payload.error) {
            return { ...state, errorFetchingPlans: action.payload.error.message, isFetchingPlans: false };
        }

        if (!action.payload.data) {
            return { ...state, errorFetchingPlans: 'No plans returned', isFetchingPlans: false };
        }

        const planDictionary = {};

        action.payload.data.forEach(x => { planDictionary[x.stripeId] = x });
        return {
            ...state,
            plans: planDictionary,
            isFetchingPlans: false,
            selectedPlan: action.payload.data[0]
        };
    }

    if (action.type == "get-customer-info") {
        if (action.payload.isLoading) {
            return { ...state, isFetchingCustomerInfo: true }
        }

        if (action.payload.error) {
            return { ...state, errorFetchingCustomerInfo: action.payload.error.message, isFetchingCustomerInfo: false };
        }

        return {
            ...state,
            customerInfo: action.payload.data,
            currency: action.payload.data?.currency ?? state.currency,
            isFetchingCustomerInfo: false
        };
    }

    if (action.type === "get-pricing") {
        if (action.payload.isLoading) {
            return { ...state, isFetchingPricing: true }
        }

        if (action.payload.error) {
            return { ...state, errorFetchingPricing: action.payload.error.message, isFetchingPricing: false };
        }

        if (!action.payload.data) {
            return { ...state, errorFetchingPricing: 'Unable to load any pricing data from the server', isFetchingPricing: false };
        }

        // ascending sort
        action.payload.data.tiers.sort((a, b) => (a.upTo ?? Number.MAX_VALUE) - (b.upTo ?? Number.MAX_VALUE));
        const unitPrice = getUnitPrice(action.payload.data, state.numberOfUsers);

        return { ...state, pricing: action.payload.data, unitPrice, isFetchingPricing: false };
    }

    if (action.type === "create-payment-link") {
        if (action.payload.isLoading) {
            return { ...state, isFetchingPaymentLink: true }
        }

        if (action.payload.error) {
            return { ...state, errorFetchingCustomerInfo: action.payload.error.message, isFetchingPaymentLink: false };
        }

        return {
            ...state,
            paymentLink: action.payload.data,
            isFetchingPaymentLink: false
        };
    }

    if (action.type === "set-currency") {
        return { ...state, currency: action.payload, paymentLink: undefined }
    }

    if (action.type === "set-number-of-users") {
        let unitPrice = state.unitPrice;

        const numberOfUsers = Math.ceil(action.payload);

        if (state.pricing) {
            unitPrice = getUnitPrice(state.pricing, numberOfUsers);
        }

        return { ...state, numberOfUsers, unitPrice, paymentLink: undefined }
    }

    if (action.type === "set-selected-plan") {
        let billingInterval = state.billingInterval;

        if ((action.payload.supportedBillingInterval & billingInterval.interval) !== billingInterval.interval) {
            billingInterval = initialBillingState.billingInterval;
        }

        return { ...state, selectedPlan: action.payload, billingInterval, paymentLink: undefined }
    }

    if (action.type === "payment-link-copied-to-clipboard") {
        return { ...state, isPaymentLinkCopiedToClipboard: action.payload };
    }

    if (action.type === "set-billing-interval") {
        return { ...state, billingInterval: action.payload };
    }

    return state;
};

const getUnitPrice = (prices: PriceDto, numberOfUsers: number) => {
    if (prices.unitAmount) {
        return prices.unitAmount
    }

    if (!prices!.tiers.length) {
        return;
    }

    const tier = prices!.tiers.find(x => (x.upTo ?? 0) > numberOfUsers);

    if (tier) {
        return tier.unitAmount ?? 0;
    }

    // if number of users bigger than any tier -> pick the last tier? or maybe the price.unitAmount?
    return prices.tiers[prices.tiers.length - 1].unitAmount
}

interface FetchPayload<TResponse> {
    isLoading?: boolean;
    data?: TResponse;
    error?: any;
}

interface GetAppPlansAction {
    type: 'get-plans';
    payload: FetchPayload<ProductDto[]>;
}

interface GetCustomerInfoAction {
    type: 'get-customer-info';
    payload: FetchPayload<CustomerDto>;
}

interface GetPricingAction {
    type: 'get-pricing';
    payload: FetchPayload<PriceDto>;
}

interface CreatePaymentLinkAction {
    type: 'create-payment-link';
    payload: FetchPayload<string>;
}

interface CopyPaymentLinkToClipboardAction {
    type: 'payment-link-copied-to-clipboard',
    payload: boolean
}

interface SetSelectedPlanAction {
    type: 'set-selected-plan',
    payload: ProductDto
}

interface SetNumberOfUsersAction {
    type: 'set-number-of-users',
    payload: number
}

interface SetCurrencyAction {
    type: 'set-currency',
    payload: string
}

interface SetBillingIntervalAction {
    type: 'set-billing-interval',
    payload: BillingIntervalOption
}

export type BillingAction =
    GetAppPlansAction | GetCustomerInfoAction | GetPricingAction | CreatePaymentLinkAction |
    SetSelectedPlanAction | SetNumberOfUsersAction | SetCurrencyAction | SetBillingIntervalAction | CopyPaymentLinkToClipboardAction;

export type BillingIntervalOption = {
    interval: SupportedBillingIntervalDtoEnum,
    key: string,
    name: string
};