import classNames from 'classnames';
import React, { ButtonHTMLAttributes, useCallback } from 'react';
import Spinner, { ClipSpinner } from '../spinner';
import './button.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean;
    textual?: boolean;
    secondary?: boolean;
    error?: Error;
}

export const Button = ({ loading, textual, secondary, error, ...rest }: ButtonProps) => {
    const onButtonClick = useCallback(
        (e: any) => {
            if (!!rest.disabled || !!loading) {
                return;
            }

            if (!!rest.onClick) {
                rest.onClick(e);
            }
        },
        [loading, rest]
    );

    const className = classNames(rest.className, {
        'textual': !!textual,
        'secondary': secondary,
        'error': !!error
    });
    return (
        <button
            {...rest}
            disabled={rest.disabled || !!loading}
            onClick={onButtonClick}
            style={{ ...rest.style, position: 'relative' }} // position relative because of loading
            className={className}
            title={error?.message}
        >
            {!!loading &&
                <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <Spinner>
                        <ClipSpinner size={20} color='#fff' />
                    </Spinner>
                </div>}
            {rest.children}
        </button>
    );
}

export default Button;