import React from 'react';
import Tile from '../../components/tile/tile';
import ViewMySubscriptionsButton from './components/viewMySubscriptionsButton';
import './fulfillmentActivationResult.scss';
import { useSubscriptionResolverContext } from './subscriptionResolverContext';


const fulfillmentActivationResult = () => {
    const { fulfillmentActivationSuccess } = useSubscriptionResolverContext();
    return (
        <div className="fulfillment-activation-result">
            <div className="fulfillment-activation-result__wrapper" style={{ margin: '0 auto' }}>
                <Tile style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '35px 60px' }}>
                {fulfillmentActivationSuccess ?
                    <>
                        <span className="fulfillment-activation-result__title">Thanks! We are processing your request.</span>
                        <br />
                        <br />
                        <span style={{ textAlign: 'center' }}>
                            Our team is working on activating your subscription. 
                            We will contact you soon to deploy the solution. You can check the status of your request in the subscription list.
                        </span>
                    </> 
                    : <>Error happend during activation</>}
                </Tile>
            </div>
            <ViewMySubscriptionsButton />
        </div>
    )
}

export default fulfillmentActivationResult;