import React, { useEffect, useState } from 'react';
import { APP_URLS } from '../../appUrls';
import POINTLOGOSMALL from '../../assets/images/point-logo-small.svg';
import appInsights from '../../common/applicationInsights';
import TrackedEventProcessEnum from '../../common/trackedEventProcessEnum';
import TrackedEventTypeEnum from '../../common/trackedEventTypeEnum';
import Icon, { IconEnum } from '../../components/icon';
import OuterLink from '../../components/outerLink/outerLink';
import { ClientApplication } from '../../msal/clientApp';
import settings from '../../settings/settings';
import { getAccountName } from '../../utils/account';
import './pageHeader.scss';
import { UserMenu } from './userMenu/userMenu';

const pageHeader = () => {
    const [accountName, setAccountName] = useState<string | undefined>();

    useEffect(
        () => {
            const account = ClientApplication.instance.getActiveAccount();
            if (account) {
                setAccountName(getAccountName(account!));
            }
        },
        []
    );

    const contactUsClicked = () => {
        appInsights.trackEvent("Contact Support Opened",
            TrackedEventTypeEnum.ContactedSysKit,
            TrackedEventProcessEnum.Default);
    }

    return (
        <div className="page-header">
            <img src={POINTLOGOSMALL} />
            <div>
                {!settings.Production && <div className="page-header__test">
                    TEST
                </div>}
                <div className="tw-flex tw-flex-row tw-items-end tw-gap-1">
                    <Icon iconName={IconEnum.Feedback} />
                    <span>Have questions? <OuterLink href={APP_URLS.faq}>Consult our FAQ</OuterLink> or <OuterLink onClick={contactUsClicked} href={APP_URLS.contactSupport}>contact us</OuterLink></span>
                </div>
            </div>
            <div className="page-header__user">
                {accountName && <UserMenu accountName={accountName} />}
            </div>
        </div>
    );
}

export default pageHeader;