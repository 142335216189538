/* eslint-disable */
import { ClientApplication } from '../msal/clientApp';
import { LOGIN_SCOPES } from '../msal/constants';
import { handleErrorResponse } from './problemDetails';


class BaseClient {
    public isAnonymous: boolean = false;

    protected async transformOptions(options_: RequestInit): Promise<RequestInit> {
        if (this.isAnonymous) {
            return options_;
        }
        
        const account = ClientApplication.instance.getActiveAccount();
        const tokenRequest = {
            scopes: [ ...LOGIN_SCOPES() ],
            account: account!            
        };

        try {
            const tokenResponse =  await ClientApplication.instance.acquireTokenSilent(tokenRequest);
    
            if(tokenResponse) {
                return Promise.resolve({
                    ...options_,
                    headers: {
                        ...options_.headers,
                        "Authorization": `Bearer ${tokenResponse.idToken}`
                    },
                    mode: 'cors',
                    credentials: 'omit'
                });
            }
            else {
                console.error('Token not found');
                return Promise.reject();
            }
        }
        catch(e) {
            if (ClientApplication.shouldForceUiInteraction(e)) {
                // fallback to interaction when silent call fails
                ClientApplication.instance.acquireTokenRedirect({
                    scopes: [ ...LOGIN_SCOPES() ],
                    account: account!
                });

                return Promise.reject('Token expired');
            }

            console.error(e);
            return Promise.reject(e);
        }

    }

    protected async transformResult(url: string, response: Response, processor: (response: Response) => any): Promise<any> {
        const clone = response.clone();
        if (!clone.ok) {
            await handleErrorResponse(response);
        }
        return processor(response);
    }

    public getBaseUrl(arg0: string, baseUrl: string | undefined): string {
        return process.env.NODE_ENV === 'development' ? 'https://localhost:44333' : '';
    }
}

export default BaseClient;