import SelectBox from 'devextreme-react/select-box';
import React from 'react';
import './regionDropdown.scss';
import locations from './regions';

interface RegionDropdownProps {
    item?: RegionItem;
    onChange(item: any): void;
}

export interface RegionItem {
    name: string;
}

const availableValues = locations.map((x) => ({ name: x } as RegionItem));

const RegionDropdown = ({ onChange, item }: RegionDropdownProps) => {
    const onValueChange = e => onChange(e.value!);

    return (
        <SelectBox
            dataSource={availableValues}
            displayExpr="name"
            valueExpr="name"
            searchMode="contains"
            searchExpr="name"
            searchEnabled={true}
            searchTimeout={200}
            value={item}
            onValueChanged={onValueChange}
            className="region-dropdown"
            dropDownOptions={{ maxHeight: '40vh' }}
        />
    )
}

export default RegionDropdown;