const locations = [
    "Australia East",
    "Australia Southeast",
    "Brazil South",
    "Canada Central",
    "Canada East",
    "Central India",
    "Central US",
    "East Asia",
    "East US",
    "East US 2",
    "France Central",
    "Germany North",
    "Germany West Central",
    "Japan East",
    "Japan West",
    "Korea Central",
    "Korea South",
    "North Central US",
    "North Europe",
    "Norway East",
    "South Africa North",
    "South Central US",
    "South India",
    "Southeast Asia",
    "Switzerland North",
    "Switzerland West",
    "UAE North",
    "UK South",
    "UK West",
    "West Central US",
    "West Europe",
    "West India",
    "West US",
    "West US 2",
    "West US 3",
  ]

  export default locations;