// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-background{position:fixed;top:-20%;left:0;z-index:12;display:flex;justify-content:center;width:100%;height:120%;background-color:rgba(244,244,244,.8)}
.dialog-background.enter-active .dialog{transform:scale(0);opacity:0}
.dialog-background.enter-done .dialog{display:flex;flex-direction:column;width:-moz-fit-content;width:fit-content;min-width:600px;max-width:90%;height:-moz-fit-content;height:fit-content;max-height:90%;background-color:#fff;border-radius:20px;box-shadow:0 12px 28px 0 rgba(0,0,0,.2),0 2px 4px 0 rgba(0,0,0,.1),inset 0 0 0 1px rgba(255,255,255,.5);transform-origin:center;transition:opacity 150ms,transform 150ms}
.dialog-background.enter-done .dialog .dialog-header{display:flex;align-items:center;justify-content:space-between;padding:12px;background-color:var(--cyber-grape-20);border-radius:20px 20px 0 0}
.dialog-background.enter-done .dialog .dialog-header .title{padding-left:8px;font-weight:600;font-size:16px}
.dialog-background.enter-done .dialog .dialog-content{flex-grow:1;max-width:100%;max-height:calc(90% - (2 * (20px + 10px + 5px)));margin:5px 0;padding:0 20px;overflow:auto}
.dialog-background.enter-done .dialog .dialog-footer{display:flex;align-items:center;justify-content:space-between;padding:10px 20px 20px 20px}
.dialog-background.enter-done .dialog .dialog-footer .close-button{margin-left:auto}
.dialog-background.exit .dialog{display:flex;flex-direction:column;width:-moz-fit-content;width:fit-content;min-width:600px;max-width:90%;height:-moz-fit-content;height:fit-content;max-height:90%;background-color:#fff;border-radius:20px;box-shadow:0 12px 28px 0 rgba(0,0,0,.2),0 2px 4px 0 rgba(0,0,0,.1),inset 0 0 0 1px rgba(255,255,255,.5);transform-origin:center}
.dialog-background.exit .dialog .dialog-header{display:flex;align-items:center;justify-content:space-between;padding:12px;background-color:var(--cyber-grape-20);border-radius:20px 20px 0 0}
.dialog-background.exit .dialog .dialog-header .title{padding-left:8px;font-weight:600;font-size:16px}
.dialog-background.exit .dialog .dialog-content{flex-grow:1;max-width:100%;max-height:calc(90% - (2 * (20px + 10px + 5px)));margin:5px 0;padding:0 20px;overflow:auto}
.dialog-background.exit .dialog .dialog-footer{display:flex;align-items:center;justify-content:space-between;padding:10px 20px 20px 20px}
.dialog-background.exit .dialog .dialog-footer .close-button{margin-left:auto}
.dialog-background.exit-active .dialog{transform:scale(0);opacity:0;transition:opacity 150ms,transform 150ms}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
