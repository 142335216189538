import React, { useCallback, useState } from 'react';
import Button from '../../../components/button/button';
import { useSubscriptionResolverContext } from '../subscriptionResolverContext';

const activateSubscriptionButton = () => {
    const { pendingFulfillmentActivationCallback, pendingFulfillmentActivationDisabled } = useSubscriptionResolverContext();
    const [ loading, setLoading ] = useState(false);

    const activateButtonCallback = useCallback(
        async () => {
            try {
                setLoading(true);
                await pendingFulfillmentActivationCallback();
                setLoading(false);
            }
            catch(e) {
                console.error(e);
                setLoading(false);
            }
        },
        [setLoading, pendingFulfillmentActivationCallback]
    )

    return (
        <div className={'activate_holder'}>
            <Button 
                loading={loading} 
                onClick={activateButtonCallback} 
                disabled={pendingFulfillmentActivationDisabled}
            >Activate Subscription</Button>
        </div>
    )
}

export default activateSubscriptionButton;