// Keep synced with SysKit.Subscriptions.Domain.UserTracking.TrackedEventTypeEnum
enum TrackedEventTypeEnum {
    RegistrationFormOpened = 1,
    GAConsentNotGiven = 2,
    ActivationProcessStarted = 3,
    ActivationProcessFailed = 4,
    ActivationProcessInProgress = 5,
    ActivationProcessCompleted = 6,
    Default = 7,
    OpenedPortal = 8,
    BuyNowClicked = 9,
    ManageStripeSubscription = 10,
    ContactedSysKit = 11
}

export default TrackedEventTypeEnum;
