import React, { useCallback, useEffect } from "react";
import SOCIAL_PROOF_LOGO from '../../assets/images/social-proof-logo.svg';
import Loading from "../../components/loading/loading";
import { WizardStep } from "../../components/wizard/step/wizardStep";
import { Wizard } from "../../components/wizard/wizard";
import { useAsync } from "../../hooks/useAsync";
import { BootstrapApplication, ClientApplication } from '../../msal/clientApp';
import { useSubscriptionContext } from "../page/subscriptionContext/subscriptionContext";
import ActivateSubscriptionFormContextProvider, { getLocalStorageKey, useActivateSubscriptionFormContext } from "./activateSubscriptionFormContext";
import { ConsentStep } from "./steps/consentStep";
import { FinishStep } from "./steps/finishStep/finishStep";
import { InstanceSetupStep } from "./steps/instanceSetupStep";
import { SignInStep } from "./steps/signInStep";

import './activateSubscriptionWizard.scss';

export enum ActivateSubscriptionWizardSteps {
    SignIn = 'sign-in',
    Configure = 'configure',
    Connect = 'connect',
    Complete = 'complete'
}

export const ActivateSubscriptionWizard: React.FC = () => {
    const { executeAsync, isLoading, error } = useAsync<void>();

    useEffect(() => {
        executeAsync(BootstrapApplication.initialize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return <>Error</>;
    }

    return (
        <ActivateSubscriptionFormContextProvider>
            <ActivateSubscriptionWizardInner />
        </ActivateSubscriptionFormContextProvider>
    )
}

const ActivateSubscriptionWizardInner: React.FC = () => {
    const { formData, isFormValid, marketingId } = useActivateSubscriptionFormContext();
    const { subscriptionData } = useSubscriptionContext();
    const account = ClientApplication.instance.getActiveAccount();

    const onFormDataFilledOut = useCallback(() => {
        localStorage.setItem(getLocalStorageKey(account!), JSON.stringify({ ...formData, "marketingId": marketingId }));
    }, [account, formData, marketingId]);

    const getInitialStep = () => {
        const activationStarted = !!(subscriptionData?.activationProgressStatus);
        if (activationStarted && ClientApplication.instance.getActiveAccount() && subscriptionData?.areResourcesProvisioned) {
            return ActivateSubscriptionWizardSteps.Complete;
        }

        if (subscriptionData?.areResourcesProvisioned === false) {
            return ActivateSubscriptionWizardSteps.Configure;
        }

        if (formData.requestedAdditionalScopes === true) {
            return ActivateSubscriptionWizardSteps.Connect;
        }

        return ActivateSubscriptionWizardSteps.SignIn;
    }

    return (
        <div className="activate-subscription-wizard">
            <Wizard
                title="Start your 21-day Free Trial"
                initialStep={getInitialStep()}
            >
                <WizardStep
                    stepId={ActivateSubscriptionWizardSteps.SignIn}
                    title="Sign In"
                    description="Your account is only used for sign in. No permissions to your tenant are required."
                    hideButton
                >
                    <SignInStep />
                </WizardStep>
                <WizardStep
                    stepId={ActivateSubscriptionWizardSteps.Configure}
                    title="Configure"
                    description="Set up your trial instance so we can customize it to your needs."
                    disableNext={!isFormValid}
                    onNext={onFormDataFilledOut}
                >
                    <InstanceSetupStep />
                </WizardStep>
                <WizardStep
                    stepId={ActivateSubscriptionWizardSteps.Connect}
                    title="Connect"
                    description="Grant Global Admin Consent to analyze your own data in Syskit Point."
                    hideButton
                >
                    <ConsentStep />
                </WizardStep>
                <WizardStep
                    stepId={ActivateSubscriptionWizardSteps.Complete}
                    title="Complete"
                    description="Sit back and relax while we make everything ready for you to start exploring."
                    hideButton
                >
                    <FinishStep />
                </WizardStep>
            </Wizard>
            <img className="tw-mt-8 tw-max-w-full" src={SOCIAL_PROOF_LOGO} />
        </div>
    );
}
