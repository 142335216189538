import React from 'react';
import { useHistory } from 'react-router-dom';
import { APP_URLS } from '../../appUrls';
import Button from '../../components/button/button';
import Spinner, { ClipSpinner } from '../../components/spinner';
import { ClientApplication } from '../../msal/clientApp';
import { SubscriptionOverviewDto } from '../../services/service';
import { onExternalLinkClickCallback } from '../../utils/url';
import './controlCenter.scss';
import { NameControlCenterTile, PurchasedPlanControlCenterTile, StatusControlCenterTile, UsersControlCenterTile, ValidUntilControlCenterTile } from './controlCenterItems';

//#region Interfaces
interface ControlCenterProps /*extends RouteComponentProps*/ {
    subscriptionOverview?: SubscriptionOverviewDto
}
//#endregion Interfaces

const controlCenter = ({ subscriptionOverview }: ControlCenterProps) => {

    const history = useHistory();

    const onBuyNowClicked = () => {
        history.push(`/control-center/${subscriptionOverview?.id}/subscriptions`);
    };

    const onContactSupportClicked = () => {
        onExternalLinkClickCallback(APP_URLS.contactSupport);
    }

    const onOpenPointClickCallback = () => {
        onExternalLinkClickCallback(subscriptionOverview!.url);
    }

    const onViewLicensesClicked = () => {
        const account = ClientApplication.instance.getActiveAccount();
        let viewLicensesUrl = subscriptionOverview!.url;
        if (account) {
            viewLicensesUrl = viewLicensesUrl + '/#/' + subscriptionOverview!.tenantId + '/settings/point-chargeable-users';
        }
        onExternalLinkClickCallback(viewLicensesUrl);
    }

    return (
        <div className="control-center control-center--container">
            <div className="control-center__main-content">
                {subscriptionOverview === undefined
                    ? <Spinner text='Loading...' style={{ height: 400, width: '80%', minWidth: '400px' }}>
                        <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                    </Spinner>
                    : (<>
                        <div className="control-center__items">
                            <div className="control-center__layout">
                                <NameControlCenterTile
                                    name={subscriptionOverview.name}
                                    url={subscriptionOverview.url}
                                />
                                <div className="control-center__layout__status">
                                    <StatusControlCenterTile
                                        status={subscriptionOverview.status}
                                        licenseExpired={subscriptionOverview.licenseExpired}
                                        resourcesProvisioned={subscriptionOverview.areResourcesProvisioned}
                                    />
                                    <ValidUntilControlCenterTile
                                        validUntil={subscriptionOverview.validUntil}
                                        licenseExpired={subscriptionOverview.licenseExpired}
                                        subscriptionState={subscriptionOverview.state}
                                    />
                                </div>
                                <UsersControlCenterTile
                                    subscriptionId={subscriptionOverview.id}
                                    plans={subscriptionOverview.plans}
                                    licensedUserCount={subscriptionOverview.minimumLicensesNeededInfo?.licensedUserCount}
                                    onViewLicensesClicked={onViewLicensesClicked}
                                />
                                <PurchasedPlanControlCenterTile
                                    plans={subscriptionOverview.plans}
                                    onBuyNowClicked={onBuyNowClicked}
                                />
                            </div>
                        </div>

                        <div className="control-center__footer">
                            <Button
                                className="open-point"
                                disabled={subscriptionOverview && !subscriptionOverview.areResourcesProvisioned}
                                onClick={onOpenPointClickCallback}
                                secondary
                            >
                                Open Syskit Point
                            </Button>

                            {subscriptionOverview && subscriptionOverview.licenseExpired &&
                                <Button onClick={onContactSupportClicked}>
                                    Contact Support
                                </Button>
                            }
                        </div>
                    </>)}
            </div>
        </div>
    );
};


export default controlCenter;
