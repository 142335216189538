import { createContext, useContext } from "react";
import { SubscriptionActivationProgressStatusEnum, SubscriptionStateDtoEnum } from '../../../services/service';

export interface SubscriptionContextProps {
    subscriptionId: string | undefined;
    activationProgressStatus?: SubscriptionActivationProgressStatusEnum | undefined;
    activationProgressMessage?: string | undefined;
    pointInstanceUrl?: string | undefined;
    areResourcesProvisioned: boolean;
    licenseExpired: boolean;
    state: SubscriptionStateDtoEnum | undefined;
    errorType?: string;
}

interface SubscriptionContextValues {
    subscriptionData: SubscriptionContextProps | undefined;
    setSubscriptionData: React.Dispatch<React.SetStateAction<SubscriptionContextProps>>;
}

export const SubscriptionContext = createContext<SubscriptionContextValues | undefined>(undefined);

export const useSubscriptionContext = () => {
    const context = useContext(SubscriptionContext);
    if (context === undefined) {
        throw new Error();
    }
    return context;
}