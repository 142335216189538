// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizard-navigation{display:flex;flex-direction:column;padding:40px 0 40px 40px}
.wizard-navigation__title{padding:50px 0 72px 30px;font-weight:700;font-size:24px;font-family:var(--secondary-font)}
.wizard-navigation__steps{display:grid;grid-template-columns:50px 1fr}
.wizard-navigation-step{padding:20px 0 8px 24px;color:var(--eigengrau-40);border-radius:8px 0 0 8px}
.wizard-navigation-step__title{margin-bottom:6px;padding-right:20px;font-weight:700;font-size:18px;font-family:var(--secondary-font);line-height:21px}
.wizard-navigation-step__description{width:360px;margin-bottom:6px;font-size:13px;line-height:19px}
.wizard-navigation-step__chain{display:flex;flex-direction:column;align-items:center}
.wizard-navigation-step__chain--current .wizard-navigation-step__dot{--circle-size: 21px;background-color:var(--electric-indigo)}
.wizard-navigation-step__chain--current .wizard-navigation-step__line--before{background-color:var(--electric-indigo)}
.wizard-navigation-step__chain.done .wizard-navigation-step__dot{background-color:var(--electric-indigo)}
.wizard-navigation-step__chain.done .wizard-navigation-step__line--before,.wizard-navigation-step__chain.done .wizard-navigation-step__line--after{background-color:var(--electric-indigo)}
.wizard-navigation-step__chain.done .wizard-navigation-step__line.hide{background-color:rgba(0,0,0,0)}
.wizard-navigation-step__dot{--circle-size: 11px;width:var(--circle-size);height:var(--circle-size);background-color:var(--electric-indigo-40);border-radius:100%}
.wizard-navigation-step__line{width:1px;background-color:var(--electric-indigo-40)}
.wizard-navigation-step__line--before{height:25px}
.wizard-navigation-step__line--after{flex-grow:1}
.wizard-navigation-step__line.hide{background-color:rgba(0,0,0,0)}
.wizard-navigation-step a{font-weight:400}
.wizard-navigation-step--current{color:var(--eigengrau);background-color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
