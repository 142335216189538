import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/button/button';
import ROUTES from '../../../routes/routes';

const viewMySubscriptionsButton = () => {
    const history = useHistory();
    const redirectToMySubscriptionsCallback = useCallback(
        () => {
            history.replace(ROUTES.MY_SUBSCRIPTIONS.ROUTE);
        },
        [history]
    )

    return (
        <div className="fulfillment-activation-result__button-wrap">
            <Button
                onClick={redirectToMySubscriptionsCallback}
            >My Subscriptions</Button>
        </div>
    )
}

export default viewMySubscriptionsButton;