import React, { useEffect, useState } from 'react';
import appInsights from "../../common/applicationInsights";
import EventProcess from "../../common/trackedEventProcessEnum";
import EventTypes from "../../common/trackedEventTypeEnum";

import { ErrorBoundary } from '../../components/errorBoundary/errorBoundary';
import { ActivateSubscriptionWizard } from '../activateSubscriptionWizard/activateSubscriptionWizard';
import PageLayout from '../page/pageLayout';
import { SubscriptionContext, SubscriptionContextProps } from '../page/subscriptionContext/subscriptionContext';
import './register.scss';

export const Register: React.FC = () => {
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionContextProps | undefined>(undefined);

    useEffect(() => {
        appInsights.trackEvent("User opened the register screen", EventTypes.OpenedPortal, EventProcess.Default);
    }, []);

    return (
        <PageLayout>
            <ErrorBoundary>
                <div className="register-content">
                    <SubscriptionContext.Provider value={{ subscriptionData, setSubscriptionData }}>
                        <ActivateSubscriptionWizard />
                    </SubscriptionContext.Provider>
                </div>
            </ErrorBoundary>
        </PageLayout>
    )
}