import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import ROUTES, { ROUTES_NAME_MAPPER } from '../../routes/routes';
import './pageTitle.scss';

const pageTitle = () => {
    const location = useLocation();

    let title = '';
    let subtitle = '';

    Object.keys(ROUTES_NAME_MAPPER).some(key => {
        const match = matchPath(location.pathname, {
            path: key,
            exact: false,
            strict: false
        });
        if (match !== null) {
            const path = ROUTES_NAME_MAPPER[match.path];
            title = ROUTES[path]?.TITLE;
            subtitle = ROUTES[path]?.SUBTITLE;
            return true;
        }
    });

    if (!title && !subtitle) {
        return null;
    }

    return (
        <div className="page-title">
            <div className="page-title__title">
                {title}
            </div>
            <div className="page-title__subtitle">
                {subtitle}
            </div>
        </div>
    )
}

export default pageTitle;