import classNames from 'classnames';
import React from 'react';
import { APP_URLS } from '../../appUrls';
import Button from '../../components/button/button';
import Icon, { IconEnum } from '../../components/icon';
import OuterLink from '../../components/outerLink/outerLink';
import { ClientApplication } from '../../msal/clientApp';
import ROUTES from '../../routes/routes';
import { getLocalStorageKey } from '../activateSubscriptionWizard/activateSubscriptionFormContext';

import './reconnectTenantBanner.scss';

interface ReconnectTenantBannerProps {
    className?: string;
}

export const ReconnectTenantBanner: React.FC<ReconnectTenantBannerProps> = props => {

    const onConnectTenantClickCallback = () => {
        const account = ClientApplication.instance.getActiveAccount();
        if (account) {
            localStorage.removeItem(getLocalStorageKey(account!));
        }

        window.location.href = `${window.location.origin + ROUTES.ACTIVATE_SUBSCRIPTION_FORM.ROUTE}`;
    }

    return (
        <div className={classNames('reconnect-tenant-banner', props.className)}>
            <div className="reconnect-tenant-inner">
                <Icon iconName={IconEnum.AlertTriangle} className="svg-icon-alert-triangle" width={24} height={24} />
                <div>
                    In accordance with our <OuterLink href={APP_URLS.eula} icon>EULA</OuterLink>, your previous Syskit Point instance was deleted. <br />
                    We apologize for the inconvenience. Please connect your tenant once again to proceed.
                </div>
            </div>
            <Button
                className="reconnect-tenant-button"
                onClick={onConnectTenantClickCallback}
            >
                Connect Tenant
            </Button>
        </div>
    );
};
