import React from 'react';

import './errorScreen.scss';

interface ErrorScreenProps {
    error: Error;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ error }) => {
    return (
        <div className="error-screen">
            <div className="error-screen__title">Something went wrong</div>
            <div className="error-screen__message">{error.message}</div>
        </div>
    );
}