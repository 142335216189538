import { getBusinessRuleErrorMessage, isBusinessRuleException } from "./businessRules";
import { ApiException } from "./service";

export default interface ProblemDetails {
    type: string;
    title: string;
    detail: string;
    status: number;
    instance: string;
    stackTrace?: string;
    correlationId?: string;
}

const PROBLEM_DETAILS_CONTENT_TYPE = "application/problem+json";

export async function handleErrorResponse(response: Response) {
    if (response.ok) {
        return;
    }

    const contentType = response.headers.get('content-type');

    if (contentType?.includes(PROBLEM_DETAILS_CONTENT_TYPE)) {
        const problemDetails = await response.json() as ProblemDetails;

        const apiException = new ApiException(getErrorMessage(problemDetails), problemDetails.status, JSON.stringify(problemDetails), {}, null);
        apiException.problemDetails = problemDetails;
        throw apiException;
    }

    const text = await response.text();
    throw new ApiException(response.statusText, response.status, text, response.headers, null);
}

export function getErrorMessage(problemDetails: ProblemDetails): string {
    if (isBusinessRuleException(problemDetails)) {
        return getBusinessRuleErrorMessage(problemDetails);
    }
    return problemDetails.detail || problemDetails.title || 'An error occurred';
}