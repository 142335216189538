import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import userRolesList, { UserRoleItem } from './userRoles';
import { tooltipRender } from '../dxComponents/itemRenderers';

interface UserRoleDropdown {
    item?: UserRoleItem;
    onChange(item: any): void;
}

const UserRoleDropdown = ({item, onChange}: UserRoleDropdown) => {
    const onValueChange = e => {
        onChange(e.selectedItem);
    }

    return (
        <SelectBox
            dataSource={userRolesList}
            displayExpr={"value"}
            valueExpr={"key"}
            searchMode="contains"
            searchEnabled={true}
            searchExpr="value"
            searchTimeout={200}
            hoverStateEnabled={false}
            dropDownOptions={{ maxHeight: '40vh' }}
            className="roles-dropdown"
            itemRender={tooltipRender('value')}
            disabled={true}
            value={item?.key}
            onSelectionChanged={onValueChange}
        />
    )
}

export default UserRoleDropdown;