import React, { AnchorHTMLAttributes } from 'react';
import Icon, { IconEnum } from '../icon';
import './outerLink.scss';

interface OuterLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    icon?: boolean;
    children?: React.ReactNode;
}

export const OuterLink: React.FC<OuterLinkProps> = ({ icon, children, ...rest }) => {
    return (
        <a
            {...rest}
            target="_blank"
        >
            {children}
            {!!icon && <Icon iconName={IconEnum.ExternalLink} />}
        </a>
    )
};

export default OuterLink;