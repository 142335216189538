// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-center--container{flex-direction:column;box-sizing:border-box;width:100%;height:100%;overflow:auto;color:var(--font-color);font-size:14px}
.control-center__main-content{position:relative;z-index:1;display:flex;flex-direction:column;height:100%;min-height:80%;overflow:hidden;text-align:center;background-color:var(--white)}
.control-center__main-content::before,.control-center__main-content::after{background-color:var(--white);box-shadow:0 2px 6px var(--shadow-color)}
.control-center__main-content::before{left:-15%;border-radius:0 0 0 100vw}
.control-center__main-content::after{right:-15%;border-radius:0 0 100vw}
.control-center__main-content--shadow-spill-helper{position:absolute;left:calc(50% - 5px);width:10px;height:calc(100% - 240px);background:var(--white)}
.control-center__main-content--header-title{z-index:0;margin:60px 0 0;font-weight:900;font-size:46px;font-family:var(--secondary-font)}
.control-center__main-content--header-description{z-index:0;margin:20px 0 60px;font-size:16px}
.control-center__main-content .control-center__items{z-index:0;display:flex;flex-wrap:wrap;justify-content:center;height:100%;overflow-x:hidden;overflow-y:auto}
.control-center__main-content .control-center-item__main--disabled{position:relative;border:1px solid var(--gray-10-solid);pointer-events:none}
.control-center__main-content .control-center-item__main--disabled div:not(.nav-card-badge){opacity:.5}
.control-center__main-content .control-center__footer{display:flex;align-items:center;justify-content:center}
.control-center__main-content .contact-support-button{float:left}
.control-center__layout{display:grid;grid-template-columns:1fr 1fr;gap:48px;width:100%;margin:32px}
.control-center__layout__status{display:grid;grid-template-columns:1fr 2fr;gap:48px}
.control-center .open-point{margin:30px 15px}
@media only screen and (max-width: 1366px){.control-center__main-content--header-title{margin-top:40px}.control-center__main-content--header-description{margin-bottom:15px}.control-center__layout{grid-template-columns:1fr}}
@media only screen and (max-width: 550px){.control-center__layout__status{grid-template-columns:1fr}}
.svg-icon-alert-triangle{vertical-align:text-top;fill:red}
.svg-icon-licensed{fill:var(--success-green)}
.svg-icon-calendar{fill:var(--eigengrau)}
.svg-icon-unlicensed{fill:var(--eigengrau)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
