import classNames from 'classnames';
import React from 'react';

import { Tooltip } from 'react-tooltip';

import Icon from '../../components/icon';

import { IconProps } from 'components/icon/icon';
import './controlCenterTile.scss';

interface ControlCenterTileProps {
    title: string;
    iconProps?: IconProps;
    className?: string;
    containerClassName?: string;
    tooltip?: string;
    action?: React.ReactNode;
    children?: React.ReactNode;
}

export const ControlCenterTile: React.FC<ControlCenterTileProps> = ({
    title,
    iconProps,
    className,
    containerClassName,
    tooltip,
    action,
    children
}) => (
    <div className={classNames('control-center-tile', containerClassName)}>
        <div className={className}>
            <div className="control-center-tile__title">
                {title}
            </div>
            {children &&
                <>
                    <div
                        className="control-center-tile__value"
                        data-tooltip-content={tooltip}
                        data-tooltip-id="tile-value"
                    >
                        {children}
                    </div>
                    {tooltip &&
                        <Tooltip
                            id="tile-value"
                            place="bottom"
                            className="control-center-tile__tooltip"
                        />}
                </>}
        </div>
        {iconProps &&
            <div className="control-center-tile__icon" data-testid="control-center-tile-icon">
                <Icon {...iconProps} />
            </div>}
        {action && <div className="control-center-tile__button">
            {action}
        </div>}
    </div>
)