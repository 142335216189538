// todo: future refactoring 
// it would be good 
// - to have a class/store/context/something which will hold plans, and have appropriate methods
// - to unify that everywhere we use SubscriptionsOverviewPlan instead of anonymous type {name: string, unitCount: number, planType: number}

import { PlanProviderTypeEnum, PlanType, SubscriptionsOverviewPlan } from '../../services/service';

// should be removed in next refactoring
export const getLicensePlansA = (plans: Array<{ name: string, unitCount: number, planType: number }>): { name: string, unitCount: number, planType: number }[] => {
    return plans.filter(p => p.planType !== PlanType.AuditRetentionAddon);
}

export const getLicensePlans = (plans: SubscriptionsOverviewPlan[]): SubscriptionsOverviewPlan[] => {
    return plans.filter(p => p.planType !== PlanType.AuditRetentionAddon);
}

export const getAuditLogDurationText = (plans: Array<{ name: string, unitCount: number, planType: number }>): string => {
    if (isInTrialPlan(plans)) {
        return '';
    }

    const auditLogYearPlan = plans.find(p => p.planType === PlanType.AuditRetentionAddon);

    const unitCount = auditLogYearPlan ? auditLogYearPlan.unitCount + 1 : 1;
    const unitValue = `year${(unitCount > 1 ? 's' : '')}`;

    return `${unitCount} ${unitValue}`;
}

export const isInTrialPlan = (plans: Array<{ name: string, unitCount: number, planType: number }>): boolean => {
    const licensePlans = getLicensePlansA(plans);

    if (licensePlans.length !== 1) {
        return false;
    }

    const licensePlan = licensePlans[0];
    return licensePlan.name === PlanProviderTypeEnum[PlanProviderTypeEnum.Trial];
}
