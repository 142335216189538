import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import appInsights from '../..//common/applicationInsights';
import SHAPES_PATTERN from '../../assets/images/login-shapes-pattern.svg';
import SUBSCRIPTIONS_LOGO from '../../assets/images/subscriptions-logo.svg';
import EventProcess from "../../common/trackedEventProcessEnum";
import EventTypes from "../../common/trackedEventTypeEnum";
import Button from "../../components/button/button";
import { LOGIN_SCOPES } from '../../msal/constants';
import settings from '../../settings/settings';

const login = () => {
    const { instance } = useMsal();
    const [loginHint, setLoginHint] = useState<string | undefined>();

    useEffect(() => {
        appInsights.trackEvent("User opened the login screen", EventTypes.OpenedPortal, EventProcess.Default);

        const urlParams = new URLSearchParams(location.search);
        const loginHintParam = urlParams.get('login_hint');
        if (loginHintParam) {
            setLoginHint(loginHintParam)
        }
    }, []);

    const onLoginClickCallback = () => {
        instance.loginRedirect({
            scopes: [...LOGIN_SCOPES()],
            prompt: 'login',
            loginHint,
            authority: loginHint ? settings.NoWorkAccountAuthority : settings.Authority
        });
    }

    return (
        <React.Fragment>
            <div className="tw-relative tw-z-0 tw-flex tw-h-full tw-items-center tw-justify-center tw-overflow-auto tw-bg-white sm:tw-overflow-hidden sm:tw-bg-space-cadet/6">
                <div className="tw-relative tw-flex tw-w-[530px] tw-max-w-full tw-flex-col tw-items-center tw-justify-center tw-rounded-[57px] tw-bg-white tw-px-20 tw-py-28 tw-text-center sm:tw-max-w-xl">
                    <img
                        src={SHAPES_PATTERN}
                        className="tw-absolute tw-right-[calc(100%-30px)] -tw-z-10 tw-hidden tw-h-5/6 sm:tw-block"
                    />
                    <img src={SUBSCRIPTIONS_LOGO} className="tw-ml-[-60px] tw-max-w-[inherit] sm:tw-max-w-none" />
                    <Button onClick={onLoginClickCallback} className="tw-mt-24 tw-rounded-[32px] tw-px-16 tw-py-4 tw-shadow-md">
                        Sign in
                    </Button>
                    <div className="tw-mt-6 tw-text-space-cadet">You will be redirected to the sign in page</div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default login;
