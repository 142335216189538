import React, { ReactElement } from 'react';
import { APP_URLS } from '../../appUrls';
import Button from "../../components/button/button";
import Icon, { IconEnum } from '../../components/icon';
import OuterLink from '../../components/outerLink/outerLink';
import { ControlCenterTile } from './controlCenterTile';

import classNames from 'classnames';
import { ContactUsEmailLink } from '../../containers/contactUsEmailLink/contactUsEmailLink';
import { PlanProviderTypeEnum, SubscriptionStateDtoEnum } from '../../services/service';
import { CustomersPortalButton } from '../customersPortalButton/customersPortalButton';
import { getAuditLogDurationText, getLicensePlansA, isInTrialPlan } from '../data/plan';
import './controlCenterItems.scss';

interface NameControlCenterTileProps {
    url: string;
    name: string;
}

export const NameControlCenterTile: React.FC<NameControlCenterTileProps> = ({ url, name }) => (
    <ControlCenterTile
        title="Name"
        className="name-tile"
    >
        {name}
    </ControlCenterTile>
);

interface StatusControlCenterTileProps {
    status?: string;
    licenseExpired?: boolean;
    resourcesProvisioned?: boolean;
}

export const StatusControlCenterTile: React.FC<StatusControlCenterTileProps> = ({
    licenseExpired,
    resourcesProvisioned,
    status
}) => {
    let statusString = 'Licensed';
    if (licenseExpired) {
        statusString = 'Inactive';
    }
    if (!status) {
        statusString = 'Deleted';
    }
    return (<ControlCenterTile
        title="Status"
        iconProps={{
            iconName: licenseExpired || !resourcesProvisioned ? IconEnum.BadgeFailed : IconEnum.BadgeChecked,
            width: 50,
            height: 50
        }}
    >
        {statusString}
    </ControlCenterTile>)

}
interface ValidUntilControlCenterTileProps {
    validUntil: string;
    licenseExpired: boolean;
    subscriptionState: SubscriptionStateDtoEnum;
}

export const ValidUntilControlCenterTile: React.FC<ValidUntilControlCenterTileProps> = ({
    validUntil,
    licenseExpired,
    subscriptionState
}) => {
    const validUntilFormatted = new Date(validUntil).toLocaleDateString();

    const validUntilString = subscriptionState === SubscriptionStateDtoEnum.Inactive ? `Your license will expire on ${validUntilFormatted}` : validUntilFormatted;

    return (
        <ControlCenterTile
            className={classNames({ "trial-expired": licenseExpired })}
            containerClassName="control-center-tile__valid-until"
            title="Next Renewal Date"
            iconProps={{
                iconName: IconEnum.Calendar,
                width: 40,
                height: 40
            }}
            tooltip={licenseExpired ? "All data will be erased 21 days after license expiration" : undefined}
        >
            {licenseExpired && <span>
                Expired on {validUntilFormatted}{' '}
                <Icon iconName={IconEnum.AlertTriangle} className="svg-icon-alert-triangle" />
            </span>}
            {!licenseExpired && validUntilString}
        </ControlCenterTile>
    )
}

interface UsersControlCenterTileProps {
    plans: Array<{ name: string, unitCount: number, planType: number }>;
    subscriptionId: string;
    licensedUserCount?: number;
    onViewLicensesClicked: () => void;
}

const getTextForPlan = (plan: { name: string, unitCount: number }, planCount: number, className: string, keyIndex: number, licensedUserCount?: number): ReactElement => {
    const classNameProvider = classNames('users-tile__name', className)

    if (plan.name === PlanProviderTypeEnum[PlanProviderTypeEnum.Trial]) {
        return <div className={classNameProvider} key={plan.name + keyIndex}>Unlimited (Trial)</div>;
    }

    const plansNameFormatted = planCount === 1 ? 'purchased' : plan.name;
    const licensedUserCountFormatted = licensedUserCount ? ' used / ' : '';
    const showLicensedUserCount = keyIndex == 0;

    return <div className={classNameProvider} key={plan.name + keyIndex}>
        {showLicensedUserCount && <span><strong>{licensedUserCount}</strong>{licensedUserCountFormatted}</span>}{plan.unitCount} {plansNameFormatted}
    </div>;
}

export const UsersControlCenterTile: React.FC<UsersControlCenterTileProps> = ({ plans, licensedUserCount, onViewLicensesClicked, subscriptionId }) => {
    const totalNumberOfLicenses = plans.reduce((a, b) => { return a + b.unitCount; }, 0);
    const isLessThanLicensesNeeded = licensedUserCount && plans.length > 0 && licensedUserCount > totalNumberOfLicenses;

    const licensePlans = getLicensePlansA(plans);

    const actionButton = !isInTrialPlan(plans) &&
        (
            <CustomersPortalButton subscriptionId={subscriptionId}>Buy More</CustomersPortalButton>
        )

    return <ControlCenterTile
        className="users-tile"
        containerClassName="users-tile__container"
        title="Licenses"
    >
        <div className="user-list-panel">
            <div className="user-list">
                {licensePlans.map((plan, index) => (
                    getTextForPlan(plan, licensePlans.length, 'plan-tile__users', index, licensedUserCount)
                ))}
                {plans.length > 0 && <Button textual onClick={onViewLicensesClicked} className='tw-mb-0 tw-pl-0 tw-text-electric-indigo hover:tw-text-cyber-grape'>View Licenses</Button>}
            </div>
            <div>
                {actionButton}
            </div>
        </div>
        {!isInTrialPlan(plans) && isLessThanLicensesNeeded ? (
            <div className="license-message-users info-line-layout">
                <Icon iconName={IconEnum.AlertTriangle} width='14' height='14' className='alert-icon'></Icon>
                <span>You need to purchase <b>{licensedUserCount}</b> licenses.</span>
                <OuterLink icon href={APP_URLS.licensedUsersCount}>How we calculate licensing</OuterLink>
            </div>) : (<div className='vert-spacer'></div>)}
    </ControlCenterTile>
};

interface PurchasedPlanControlCenterTileProps {
    plans: Array<{ name: string, unitCount: number, planType: number }>;

    onBuyNowClicked: () => void;
}

export const PurchasedPlanControlCenterTile: React.FC<PurchasedPlanControlCenterTileProps> = ({ plans, onBuyNowClicked }) => {

    const actionButton = isInTrialPlan(plans) &&
        (<Button
            onClick={onBuyNowClicked}
        >
            Buy Now
        </Button>);

    const licensePlans = getLicensePlansA(plans);

    return (
        <ControlCenterTile
            className="plan-tile"
            containerClassName="plan-tile__container"
            title="Purchased Plan"
        >
            <div className='plan-list-panel'>
                <div className='plan-list'>
                    {licensePlans.map((plan, index) => (
                        <div className={classNames('plan-tile__name', 'plan-tile__purchased-plans')} key={plan.name + index}>{plan.name}</div>
                    ))}
                    <span> with {getAuditLogDurationText(plans)} Audit Logs Storage</span>
                </div>
                <div>
                    {actionButton}
                </div>
            </div>
            {!isInTrialPlan(plans) ? (<div className="audit-logs-contact-info-line info-line-layout">
                <div>
                    <span>Need Longer </span>
                    <OuterLink icon href={APP_URLS.auditLogsRetention}>Audit Logs Retention?</OuterLink>
                </div>
                <ContactUsEmailLink>Contact us</ContactUsEmailLink>
            </div>) : (<div className='vert-spacer'></div>)
            }
        </ControlCenterTile>
    );

}