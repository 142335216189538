import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { BootstrapApplication } from '../../msal/clientApp';
import { DIRECTORY_ACCESS_AS_USER_SCOPE, ENTERPRISE_APP_LOGIN_SCOPES } from '../../msal/constants';

export async function acquireTokenSilentWithRetry() {
    const instance = BootstrapApplication.instance;
    const account = instance.getActiveAccount();

    if (account === null) {
        return undefined;
    }

    const tokenRequest: SilentRequest = {
        scopes: [...ENTERPRISE_APP_LOGIN_SCOPES()],
        account: account,
        forceRefresh: true
    };

    let tokenResponse: AuthenticationResult;

    // It takes some time for Azure to be aware that the consent has been given. We will try every 3 seconds for 15 minutes to get it
    let tries = 0;
    while (tries++ < 300) {
        try {
            tokenResponse = await instance.acquireTokenSilent(tokenRequest);

            if (tokenResponse.scopes.filter(x => x.indexOf(DIRECTORY_ACCESS_AS_USER_SCOPE) > -1).length === 0) {
                await sleep(3000);
                continue;
            }

            return tokenResponse;
        } catch (e) {
            await sleep(3000);
            continue;
        }
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
