import { TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../../components/button/button';
import Spinner, { ClipSpinner } from '../../components/spinner';
import UserRoleDropDown from '../../components/userRoleDropdown/userRoleDropdown';
import userRolesList from '../../components/userRoleDropdown/userRoles';
import { SubscriptionUserDto, SysKitSubscriptionsClient } from '../../services/service';
import './editUserForm.scss';

const STRINGS = {
    LOADING: 'Loading...',
    TITLE: 'Edit User',
    INPUTS: {
        NAME: {
            LABEL: 'Name'
        },
        EMAIL: {
            LABEL: 'E-mail'
        },
        ROLE: {
            LABEL: 'Role'
        }
    },
    CLOSE_BUTTON: 'Back',
    SAVE_BUTTON: 'Save',
    ERROR_MESSAGE: 'An error occurred while editing user. Please try again or contact support.'
};

interface EditUserParams {
    subscriptionId: string;
    userId: string;
}

export const EditUserForm = () => {
    const history = useHistory();
    const { subscriptionId, userId } = useParams() as EditUserParams;
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [user, setUser] = useState<SubscriptionUserDto>();

    useEffect(
        () => {
            const getUserById = async () => {
                setLoading(true);

                try {
                    const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
                    const userResponse = await syskitSubscriptionsClient.getUserById(subscriptionId, userId);
                    setUser(userResponse);
                } catch (error) {
                    console.error(error);
                    setHasError(true);
                }
                finally {
                    setLoading(false);
                }
            }

            getUserById();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    //#region Actions

    const onNameChange = (value: string) => {
        setUser({ ...user!, name: value });
    };

    const onCloseClicked = () => {
        history.goBack();
    };

    const onSaveClicked = async () => {
        setLoading(true);

        try {

            const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
            await syskitSubscriptionsClient.updateUser(subscriptionId, userId, { displayName: user!.name });

            history.goBack();
        } catch (error) {
            console.error(error);
            setHasError(true);
        }
        finally {
            setLoading(false);
        }
    };

    //#endregion Actions

    //#region Rendering

    const renderDetail = (label: string, field: () => React.JSX.Element) => {
        return (
            <div className="edit-user-detail">
                <div className="edit-user-detail__label">{label}</div>
                <div className="edit-user-detail__value">{field()}</div>
            </div>
        );
    };

    const renderNameField = () => {
        return (
            <div className="edit-user-form__field">
                <TextBox
                    value={user?.name}
                    onValueChanged={(e) => onNameChange(e.value)}
                />
            </div>
        );
    };

    const renderEmailField = () => {
        return (
            <div className="edit-user-form__field">
                <TextBox
                    value={user?.email}
                    disabled={true}
                />
            </div>
        );
    };

    const renderRoleField = () => {
        return (
            <div className="edit-user-form__field">
                <UserRoleDropDown
                    item={userRolesList.find(i => i.key === user?.role)}
                    onChange={() => void 0} />
            </div>
        );
    };

    //#endregion Rendering

    const showLoader = loading;
    const showContent = !showLoader;

    return (
        <>
            {showLoader &&
                <Spinner text={STRINGS.LOADING} style={{ height: 400, width: '80%', minWidth: '400px' }}>
                    <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                </Spinner>
            }
            {showContent &&
                <div className="edit_user__form">
                    <div>
                        <div className="edit_user__title">{STRINGS.TITLE}</div>

                        {renderDetail(STRINGS.INPUTS.NAME.LABEL, renderNameField)}
                        {renderDetail(STRINGS.INPUTS.EMAIL.LABEL, renderEmailField)}
                        {renderDetail(STRINGS.INPUTS.ROLE.LABEL, renderRoleField)}

                        {hasError && <div className="edit_user__error">{STRINGS.ERROR_MESSAGE}</div>}

                        <div className="edit_user__footer">
                            <Button
                                className="close-button"
                                onClick={onCloseClicked}
                                textual
                            >{STRINGS.CLOSE_BUTTON}</Button>
                            <Button
                                className="edit-save-button"
                                onClick={onSaveClicked}
                            >{STRINGS.SAVE_BUTTON}</Button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
