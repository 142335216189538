
export interface TimeZoneItem {
    key: string;
    value: string;
}

const timeZonesList: Array<TimeZoneItem> = [
{ key: "Morocco Standard Time", value: "(GMT) Casablanca" },
{ key: "GMT Standard Time", value: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London" },
{ key: "Greenwich Standard Time", value: "(GMT) Monrovia, Reykjavik" },
{ key: "W. Europe Standard Time", value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
{ key: "Central Europe Standard Time", value: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
{ key: "Romance Standard Time", value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
{ key: "Central European Standard Time", value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
{ key: "W. Central Africa Standard Time", value: "(GMT+01:00) West Central Africa" },
{ key: "Jordan Standard Time", value: "(GMT+02:00) Amman" },
{ key: "GTB Standard Time", value: "(GMT+02:00) Athens, Bucharest, Istanbul" },
{ key: "Middle East Standard Time", value: "(GMT+02:00) Beirut" },
{ key: "Egypt Standard Time", value: "(GMT+02:00) Cairo" },
{ key: "South Africa Standard Time", value: "(GMT+02:00) Harare, Pretoria" },
{ key: "FLE Standard Time", value: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
{ key: "Israel Standard Time", value: "(GMT+02:00) Jerusalem" },
{ key: "E. Europe Standard Time", value: "(GMT+02:00) Minsk" },
{ key: "Namibia Standard Time", value: "(GMT+02:00) Windhoek" },
{ key: "Arabic Standard Time", value: "(GMT+03:00) Baghdad" },
{ key: "Arab Standard Time", value: "(GMT+03:00) Kuwait, Riyadh" },
{ key: "Russian Standard Time", value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
{ key: "E. Africa Standard Time", value: "(GMT+03:00) Nairobi" },
{ key: "Georgian Standard Time", value: "(GMT+03:00) Tbilisi" },
{ key: "Iran Standard Time", value: "(GMT+03:30) Tehran" },
{ key: "Arabian Standard Time", value: "(GMT+04:00) Abu Dhabi, Muscat" },
{ key: "Azerbaijan Standard Time", value: "(GMT+04:00) Baku" },
{ key: "Mauritius Standard Time", value: "(GMT+04:00) Port Louis" },
{ key: "Caucasus Standard Time", value: "(GMT+04:00) Yerevan" },
{ key: "Afghanistan Standard Time", value: "(GMT+04:30) Kabul" },
{ key: "Ekaterinburg Standard Time", value: "(GMT+05:00) Ekaterinburg" },
{ key: "Pakistan Standard Time", value: "(GMT+05:00) Islamabad, Karachi" },
{ key: "West Asia Standard Time", value: "(GMT+05:00) Tashkent" },
{ key: "India Standard Time", value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
{ key: "Sri Lanka Standard Time", value: "(GMT+05:30) Sri Jayawardenepura" },
{ key: "Nepal Standard Time", value: "(GMT+05:45) Kathmandu" },
{ key: "N. Central Asia Standard Time", value: "(GMT+06:00) Almaty, Novosibirsk" },
{ key: "Central Asia Standard Time", value: "(GMT+06:00) Astana, Dhaka" },
{ key: "Myanmar Standard Time", value: "(GMT+06:30) Yangon (Rangoon)" },
{ key: "SE Asia Standard Time", value: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
{ key: "North Asia Standard Time", value: "(GMT+07:00) Krasnoyarsk" },
{ key: "China Standard Time", value: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
{ key: "North Asia East Standard Time", value: "(GMT+08:00) Irkutsk, Ulaan Bataar" },
{ key: "Singapore Standard Time", value: "(GMT+08:00) Kuala Lumpur, Singapore" },
{ key: "W. Australia Standard Time", value: "(GMT+08:00) Perth" },
{ key: "Taipei Standard Time", value: "(GMT+08:00) Taipei" },
{ key: "Tokyo Standard Time", value: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
{ key: "Korea Standard Time", value: "(GMT+09:00) Seoul" },
{ key: "Yakutsk Standard Time", value: "(GMT+09:00) Yakutsk" },
{ key: "Cen. Australia Standard Time", value: "(GMT+09:30) Adelaide" },
{ key: "AUS Central Standard Time", value: "(GMT+09:30) Darwin" },
{ key: "E. Australia Standard Time", value: "(GMT+10:00) Brisbane" },
{ key: "AUS Eastern Standard Time", value: "(GMT+10:00) Canberra, Melbourne, Sydney" },
{ key: "West Pacific Standard Time", value: "(GMT+10:00) Guam, Port Moresby" },
{ key: "Tasmania Standard Time", value: "(GMT+10:00) Hobart" },
{ key: "Vladivostok Standard Time", value: "(GMT+10:00) Vladivostok" },
{ key: "Central Pacific Standard Time", value: "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
{ key: "New Zealand Standard Time", value: "(GMT+12:00) Auckland, Wellington" },
{ key: "Fiji Standard Time", value: "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
{ key: "Tonga Standard Time", value: "(GMT+13:00) Nuku’alofa" },
{ key: "Azores Standard Time", value: "(GMT-01:00) Azores" },
{ key: "Cape Verde Standard Time", value: "(GMT-01:00) Cape Verde Is." },
{ key: "Mid-Atlantic Standard Time", value: "(GMT-02:00) Mid-Atlantic" },
{ key: "E. South America Standard Time", value: "(GMT-03:00) Brasilia" },
{ key: "Argentina Standard Time", value: "(GMT-03:00) Buenos Aires" },
{ key: "SA Eastern Standard Time", value: "(GMT-03:00) Georgetown" },
{ key: "Greenland Standard Time", value: "(GMT-03:00) Greenland" },
{ key: "Montevideo Standard Time", value: "(GMT-03:00) Montevideo" },
{ key: "Newfoundland Standard Time", value: "(GMT-03:30) Newfoundland" },
{ key: "Atlantic Standard Time", value: "(GMT-04:00) Atlantic Time (Canada)" },
{ key: "SA Western Standard Time", value: "(GMT-04:00) La Paz" },
{ key: "Central Brazilian Standard Time", value: "(GMT-04:00) Manaus" },
{ key: "Pacific SA Standard Time", value: "(GMT-04:00) Santiago" },
{ key: "Venezuela Standard Time", value: "(GMT-04:30) Caracas" },
{ key: "SA Pacific Standard Time", value: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
{ key: "Eastern Standard Time", value: "(GMT-05:00) Eastern Time (US & Canada)" },
{ key: "US Eastern Standard Time", value: "(GMT-05:00) Indiana (East)" },
{ key: "Central America Standard Time", value: "(GMT-06:00) Central America" },
{ key: "Central Standard Time", value: "(GMT-06:00) Central Time (US & Canada)" },
{ key: "Central Standard Time (Mexico)", value: "(GMT-06:00) Guadalajara, Mexico City,Monterrey" },
{ key: "Canada Central Standard Time", value: "(GMT-06:00) Saskatchewan" },
{ key: "US Mountain Standard Time", value: "(GMT-07:00) Arizona" },
{ key: "Mountain Standard Time (Mexico)", value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
{ key: "Mountain Standard Time", value: "(GMT-07:00) Mountain Time (US & Canada)" },
{ key: "Pacific Standard Time", value: "(GMT-08:00) Pacific Time (US & Canada)" },
{ key: "Pacific Standard Time (Mexico)", value: "(GMT-08:00) Tijuana, Baja Cali" },
{ key: "Alaskan Standard Time", value: "(GMT-09:00) Alaska" },
{ key: "Hawaiian Standard Time", value: "(GMT-10:00) Hawaii" },
{ key: "Samoa Standard Time", value: "(GMT-11:00) Midway Island, Samoa" },
{ key: "Dateline Standard Time", value: "(GMT-12:00) International Date Line West" }
]

export default timeZonesList;