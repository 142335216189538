import React from 'react';

import { ContactUsEmailLink } from '../../containers/contactUsEmailLink/contactUsEmailLink';
import './billingFooter.scss';

export const BillingFooter: React.FC = () => (
    <div className="footer">
        <hr />
        <p>Need assistance? Contact your sales representative or email us at <ContactUsEmailLink>sales@syskit.com</ContactUsEmailLink></p>
    </div>
)