import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import Spinner, { ClipSpinner } from '../../components/spinner';
import ROUTES from '../../routes/routes';
import { ResolvedSubscriptionDto, SubscriptionClient, SubscriptionStatusEnumExtension } from '../../services/service';

interface Dropdown {
    item: any;
    setItem(val: any): void;
}

interface UserInput {
    regionDropdown: Dropdown;
    timezoneDropdown: Dropdown;
}

interface SubscriptionResolverContextProps {
    subscription?: ResolvedSubscriptionDto;
    pendingFulfillmentActivationCallback(): Promise<void>;
    pendingFulfillmentActivationDisabled: boolean;
    fulfillmentActivationSuccess: boolean;
    userInput: UserInput;
}

const subscriptionResolverContext = React.createContext<SubscriptionResolverContextProps | undefined>(undefined);
export const useSubscriptionResolverContext = () => {
    const context = useContext(subscriptionResolverContext);
    return context as SubscriptionResolverContextProps;
}

const subscriptionResolverProvider = (props) => {
    const [region, setRegion] = useState<string | undefined>();
    const [timeZone, setTimeZone] = useState<string | undefined>();

    const urlTokenKey = 'token';

    const [loading, setLoading] = useState(false);
    const [resolvedSubscription, setResolvedSubscription] = useState<ResolvedSubscriptionDto | undefined>();

    const [fulfillmentActivationSuccess, setFulfillmentActivationSuccess] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const redirectToResultPage = useCallback(
        () => {
            const urlParams = new URLSearchParams(location.search);
            const redirectToRoute = generatePath(ROUTES.SUBSCRIPTION_RESOLVER_RESULT.ROUTE, { id: urlParams.get(urlTokenKey)! });
            history.push(redirectToRoute);
        },
        [history, location]
    )

    const redirectIfNecessary = useCallback(
        (status: SubscriptionStatusEnumExtension) => {
            if (status == SubscriptionStatusEnumExtension.PendingActivation) {
                setFulfillmentActivationSuccess(true);
                redirectToResultPage();
            }
            else if (status != SubscriptionStatusEnumExtension.PendingFulfillmentStart) {
                history.replace(ROUTES.MY_SUBSCRIPTIONS.ROUTE);
            }
        },
        [history, redirectToResultPage, setFulfillmentActivationSuccess]
    )

    const resolveToken = useCallback(async (token: string) => {
        const subscriptionClient = new SubscriptionClient();
        try {
            setLoading(true);
            const result = await subscriptionClient.resolveSubscription(token);
            redirectIfNecessary(result.status);
            setResolvedSubscription(result);
            setLoading(false);

        } catch (e) {
            setLoading(false);
            console.error(e);
        }
    }, [redirectIfNecessary])

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        if (urlParams.has(urlTokenKey)) {
            const token = urlParams.get(urlTokenKey);
            resolveToken(token!);
        }
        else {
            history.replace(ROUTES.MY_SUBSCRIPTIONS.ROUTE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pendingFulfillmentActivation = useCallback(
        async () => {
            try {
                const client = new SubscriptionClient();
                await client.activatePendingFulfillment(resolvedSubscription?.subscriptionId!, {
                    region: region,
                    defaultTimeZone: timeZone
                });
                setFulfillmentActivationSuccess(true);
            }
            catch (e) {
                console.error(e);
                setFulfillmentActivationSuccess(false)
            }
            redirectToResultPage();
        },
        [redirectToResultPage, resolvedSubscription?.subscriptionId, region, timeZone]
    )

    const pendingFulfillmentActivationDisabled = useMemo(() => !region || !timeZone, [region, timeZone]);

    const contextValue = useMemo(
        () => {
            return {
                pendingFulfillmentActivationCallback: pendingFulfillmentActivation,
                subscription: resolvedSubscription,
                fulfillmentActivationSuccess,
                userInput: {
                    regionDropdown: {
                        item: region,
                        setItem: setRegion
                    },
                    timezoneDropdown: {
                        item: timeZone,
                        setItem: setTimeZone
                    }
                },
                pendingFulfillmentActivationDisabled
            } as SubscriptionResolverContextProps;
        },
        [pendingFulfillmentActivation, resolvedSubscription, fulfillmentActivationSuccess, region, timeZone, pendingFulfillmentActivationDisabled]
    );

    return (
        <subscriptionResolverContext.Provider value={contextValue}>
            {loading ?
                <Spinner text='Loading...' style={{ height: 400 }}>
                    <ClipSpinner size={70} color={'var(--electric-indigo)'} />
                </Spinner>
                : <>{props.children}</>}
        </subscriptionResolverContext.Provider>
    )
}

export default subscriptionResolverProvider;