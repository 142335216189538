import React from 'react';
import PageHeader from '../../containers/page/pageHeader';
import PageLayout from '../../containers/page/pageLayout';
import { ErrorBoundary } from '../errorBoundary/errorBoundary';

import './invitationExpired.scss';

export const InvitationExpired: React.FC = () => {
    return (
        <PageLayout>
            <ErrorBoundary>
                <PageHeader />
                <div className="invitation-expired__message">The invitation has expired. Please contact your subscription administrator to request a new invite.</div>
            </ErrorBoundary>
        </PageLayout>
    )
}