import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { APP_URLS } from "../../../../../appUrls";
import appInsights from "../../../../../common/applicationInsights";
import EventProcess from "../../../../../common/trackedEventProcessEnum";
import EventTypes from "../../../../../common/trackedEventTypeEnum";
import Loading from "../../../../../components/loading/loading";
import { useInterval } from "../../../../../hooks/useInterval";
import { ClientApplication } from '../../../../../msal/clientApp';
import BaseClient from "../../../../../services/baseClient";
import { NoPreDeployedPointInstanceAvailableRuleType } from '../../../../../services/businessRules';
import { handleErrorResponse } from "../../../../../services/problemDetails";
import { ApiException, PointInstanceStatusDtoEnum, SubscriptionActivationProgressStatusEnum, SysKitSubscriptionsClient } from "../../../../../services/service";
import { getAccountName } from '../../../../../utils/account';
import { onExternalLinkClickCallback } from '../../../../../utils/url';
import { useSubscriptionContext } from "../../../../page/subscriptionContext/subscriptionContext";
import { getLocalStorageKey } from "../../../activateSubscriptionFormContext";
import { acquireTokenSilentWithRetry } from "../../../activation.helpers";
import { Button } from './../../../../../components/button/button';
import './subscriptionConfigurationProgress.scss';

interface ActivationFormDataDto {
    url: string;
    companyName: string;
    octopusTenantId?: number;
    dataRegion: string;
    timeZone: string;
    marketingId: string;
}

const steps = 4;
// This will be 30 minutes
const MaxNumberOfTries = 600;
// 3 seconds
const WaitTime = 3000;

const subscriptionConfigurationProgress = () => {
    const [isTimeout, setIsTimeout] = useState(false);
    const [text, setText] = useState('Checking status...');
    const [status, setStatus] = useState<PointInstanceStatusDtoEnum>(PointInstanceStatusDtoEnum.ResourcesDeployed);
    const [progressStatus, setProgressStatus] = useState<SubscriptionActivationProgressStatusEnum>();
    const [activationFormData, setActivationFormData] = useState<ActivationFormDataDto>();
    const { subscriptionData, setSubscriptionData } = useSubscriptionContext();
    const history = useHistory();

    const shouldCheckProgressInfo = !!subscriptionData?.subscriptionId;
    const progressInfoInFailedState = progressStatus === SubscriptionActivationProgressStatusEnum.Failed || progressStatus === SubscriptionActivationProgressStatusEnum.FailedAfterMaxRetries;
    const shouldStopProgressInfo = status === PointInstanceStatusDtoEnum.ConnectedToTenant || progressInfoInFailedState;

    const checkStatusHandler = useCallback(async () => {
        if (!shouldCheckProgressInfo) {
            return;
        }

        const b2caccount = ClientApplication.instance.getActiveAccount();
        const accountKey = getLocalStorageKey(b2caccount!);

        const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
        try {
            const pointInfo = await syskitSubscriptionsClient.getProgressInfoForSubscription(subscriptionData?.subscriptionId!);

            const { status: $status, url, message, progressStatus: $progressStatus } = pointInfo;

            if ($progressStatus === SubscriptionActivationProgressStatusEnum.NotStarted) {
                // If tenant connection is not started yet, wait for next cycle
                return;
            }

            // check whether polling progress status should be continued
            if (shouldStopProgressInfo) {
                stop();
                // release the registration form data
                localStorage.removeItem(accountKey!);
            }

            setText(message);
            setProgressStatus($progressStatus);
            setStatus($status);
            setSubscriptionData(val => ({
                ...val,
                activationProgressStatus: $progressStatus,
                activationProgressMessage: message,
                pointInstanceUrl: url,
                areResourcesProvisioned: $status === PointInstanceStatusDtoEnum.ConnectedToTenant
            }));
        } catch (e) {
            setText('Error getting Syskit Point Instance');
            stop();
            // release the registration form data
            localStorage.removeItem(accountKey!);
            return;
        }

        // release the registration form data
        localStorage.removeItem(accountKey!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData, progressStatus])

    const { start, stop } = useInterval(
        checkStatusHandler,
        WaitTime,
        {
            maxIterations: MaxNumberOfTries,
            onTimeout: () => setIsTimeout(true)
        });

    const handleActivation = async (callback: any) => {
        // get the activation form data from local storage
        const b2cAccount = ClientApplication.instance.getActiveAccount();
        const accountKey = getLocalStorageKey(b2cAccount!);

        // Only activate the account if there is no subscription already assigned to the tenant or previous attempt failed. 
        // Otherwise, start checking the progress of the activation
        const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
        const subscriptionResult = await syskitSubscriptionsClient.getSubscriptionForUser();

        if (subscriptionResult && subscriptionResult.areResourcesProvisioned && !progressInfoInFailedState) {
            setSubscriptionData(val => ({
                subscriptionId: subscriptionResult.subscriptionId,
                activationProgressStatus: subscriptionResult.activationProgressStatus,
                activationProgressMessage: subscriptionResult.activationProgressMessage,
                pointInstanceUrl: subscriptionResult.pointInstanceUrl,
                licenseExpired: subscriptionResult.licenseExpired,
                state: subscriptionResult.state,
                areResourcesProvisioned: subscriptionResult.areResourcesProvisioned
            }));

            return;
        }

        setText('Getting started...');

        const cachedFormData = localStorage.getItem(accountKey!);

        if (cachedFormData) {
            setActivationFormData(JSON.parse(cachedFormData) as ActivationFormDataDto);
        }

        if (!cachedFormData && !activationFormData) {
            return;
        }

        const formData = activationFormData ?? JSON.parse(cachedFormData!);

        const tokenResponse = await acquireTokenSilentWithRetry();

        if (!tokenResponse) {
            return;
        }

        try {
            let requestDto = null as any;
            if (formData !== null) {
                requestDto = {
                    desiredSubdomain: formData.url,
                    octopusTenantName: formData.companyName,
                    octopusTenantId: formData.octopusTenantId,
                    region: formData.dataRegion,
                    timezone: formData.timeZone,
                    environment: '', // TODO: sometime in the future
                    marketingId: formData.marketingId,
                    additionalAdminAccounts: [{
                        tenantId: b2cAccount!.tenantId,
                        homeTenantId: b2cAccount!.tenantId,
                        displayName: getAccountName(b2cAccount!),
                        userId: b2cAccount!.localAccountId,
                        username: b2cAccount!.username,
                        email: b2cAccount!.idTokenClaims!.email
                    }]
                };
            }

            const trackEventText = status !== PointInstanceStatusDtoEnum.ResourcesDeprovisioned ? "User started the registration process" : "User started reactivation on already active subscription";
            appInsights.trackEvent(trackEventText, EventTypes.ActivationProcessStarted, EventProcess.SubscriptionActivationProcess);

            const response = await fetch(`${(new BaseClient()).getBaseUrl('', '')}/api/syskit-subscriptions/activate`, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${tokenResponse.accessToken}`
                },
                mode: 'cors',
                credentials: 'omit',
                method: 'POST',
                body: JSON.stringify(requestDto)
            });

            if (!response.ok) {
                console.error(response.statusText);
                await handleErrorResponse(response);
            }

            const json = await response.text();
            setSubscriptionData(val => ({
                ...val,
                subscriptionId: JSON.parse(json),
                activationProgressStatus: SubscriptionActivationProgressStatusEnum.InProgress
            }));

            if (callback) {
                callback();
            }
        }
        catch (e) {
            const apiException = e as ApiException;
            if (apiException) {
                const message = e.message ?? 'Something went wrong';
                setText(message);
                setProgressStatus(SubscriptionActivationProgressStatusEnum.Failed);
                setSubscriptionData(val => ({
                    ...val,
                    activationProgressStatus: SubscriptionActivationProgressStatusEnum.Failed,
                    activationProgressMessage: message,
                    errorType: e.problemDetails.type
                }));
                stop();
                // release the registration form data                   
                localStorage.removeItem(accountKey!);
            }
        }
    }

    useEffect(() => {
        handleActivation(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history])

    const onRetryButtonClicked = async () => {
        const syskitSubscriptionsClient = new SysKitSubscriptionsClient();
        try {
            if (subscriptionData?.errorType === NoPreDeployedPointInstanceAvailableRuleType) {
                // Try activate once again
                handleActivation(start);
                return;
            }
            const progressInfo = await syskitSubscriptionsClient.resetProgressInfo(subscriptionData?.subscriptionId!);
            setProgressStatus(progressInfo.status);
            handleActivation(start);
        } catch (e) {
            setText('Error restarting activation.');
        }
    }

    return (
        <div className="configuration-progress">
            <ProgressBody
                instanceStatus={status}
                progressStatus={progressStatus!}
                message={text}
                isTimeout={isTimeout}
                onRetryButtonClick={onRetryButtonClicked}
            />
        </div >
    )
}

interface ProgressBodyProps {
    progressStatus: SubscriptionActivationProgressStatusEnum;
    instanceStatus: PointInstanceStatusDtoEnum;
    message: string;
    isTimeout: boolean;
    onRetryButtonClick(): void;
}

const ProgressBody: React.FC<ProgressBodyProps> = ({ instanceStatus, isTimeout, message, progressStatus, onRetryButtonClick }) => {
    if (isTimeout) {
        return (
            <div className="configuration-progress__progress">
                This is taking longer than usual. If the problem persists please contact <a href={APP_URLS.contactSupport} target="_blank" rel="noreferrer">Syskit support</a>
            </div>
        );
    }

    const progressInfoInFailedState = progressStatus === SubscriptionActivationProgressStatusEnum.Failed || progressStatus === SubscriptionActivationProgressStatusEnum.FailedAfterMaxRetries;
    const showContactSupportButton = progressStatus === SubscriptionActivationProgressStatusEnum.Failed && message.startsWith('A free trial was already activated with this tenant.');
    const text = progressInfoInFailedState ||
        progressStatus === SubscriptionActivationProgressStatusEnum.InternalRetry ||
        instanceStatus === PointInstanceStatusDtoEnum.ResourcesDeployed ||
        instanceStatus === PointInstanceStatusDtoEnum.QueuedForDeletion
        ? message
        : `${message} ${instanceStatus - 1}/${steps}`

    return (
        <LoadingWrapper isLoading={!progressInfoInFailedState}>
            <div className="configuration-progress__title">
                <b>Thanks! We are processing your request.</b>
            </div>
            <div className="configuration-progress__subtitle">Your Syskit Point instance is being provisioned.</div>
            <div
                className={classNames(
                    "configuration-progress__progress",
                    {
                        "configuration-progress__error-message": progressInfoInFailedState
                    })}>
                {text}
            </div>
            {showContactSupportButton && <Button onClick={() => onExternalLinkClickCallback(APP_URLS.trialExpiredForm)}>Contact Support</Button>}
            {!showContactSupportButton && progressInfoInFailedState && <Button onClick={onRetryButtonClick}>Retry</Button>}
        </LoadingWrapper>
    );
}

const LoadingWrapper: React.FC<{ isLoading: boolean, children: React.ReactNode }> = ({ isLoading, children }) => isLoading
    ? <Loading className="configuration-progress__loading">{children}</Loading>
    : <div className="configuration-progress__loading">{children}</div>;



export default subscriptionConfigurationProgress;