// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-trial-form__tile{box-sizing:border-box;min-width:800px;padding:50px 70px 0}
.connect-trial-form .label_detail{margin-bottom:30px}
.connect-trial-form__field{display:flex;align-items:center}
.connect-trial-form__field .dx-texteditor-input{width:270px}
.connect-trial-form__field span::before{white-space:pre;content:" "}
.connect-trial-form__checkbox{white-space:nowrap}
.connect-trial-form__checkbox a{color:var(--cyber-grape)}
.connect-trial-form__checkbox span{word-break:keep-all}
.connect-trial-form__checkbox span::before{display:inline-block;width:6px}
.connect-trial-form .svg-icon-circleCheck{min-width:20px;min-height:20px;margin-left:8px;fill:var(--success-green)}
.submit-trial-button-wrap{display:flex;justify-content:center;margin:32px 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
