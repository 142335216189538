import settings from "../settings/settings";

export const BASE_LOGIN_SCOPES = ["offline_access", "openid", "profile", "email"];

export const LOGIN_SCOPES = () => [
  ...BASE_LOGIN_SCOPES,
  `${settings.AdB2CClientId}`
];

export const ENTERPRISE_APP_LOGIN_SCOPES = () => [
  `${settings.BootstrapClientId}/.default`,
  ...BASE_LOGIN_SCOPES
];
export const DIRECTORY_ACCESS_AS_USER_SCOPE = "Directory.AccessAsUser.All";

export enum RedirectResponseState {
  None = '',
  GetAdditionalScopes = 'getAdditionalScopes',
  SignIn = 'signIn'
}