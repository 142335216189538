import { AuthenticationResult, AuthError } from '@azure/msal-browser';
import React, { useEffect, useState } from 'react';
import { APP_URLS } from '../../../appUrls';
import MICROSOFT_LOGO from '../../../assets/images/microsoft.png';
import appInsights from '../../../common/applicationInsights';
import TrackedEventProcessEnum from '../../../common/trackedEventProcessEnum';
import TrackedEventTypeEnum from '../../../common/trackedEventTypeEnum';
import Button from '../../../components/button/button';
import Loading from '../../../components/loading/loading';
import OuterLink from '../../../components/outerLink/outerLink';
import { useWizard } from '../../../components/wizard/wizardContext';
import { useAsync } from '../../../hooks/useAsync';
import { BootstrapApplication, ClientApplication } from '../../../msal/clientApp';
import { ENTERPRISE_APP_LOGIN_SCOPES } from '../../../msal/constants';
import { GlobalAdminCheckDto } from '../../../services/service';
import { getLocalStorageKey, useActivateSubscriptionFormContext } from '../activateSubscriptionFormContext';
import { ActivateSubscriptionWizardSteps } from '../activateSubscriptionWizard';
import { assertHasPermissions, checkGlobalAdminAccess, getAdditionalScopes } from './consentStep.helpers';
import './consentStep.scss';

export const ConsentStep: React.FC = () => {
    const { setStep } = useWizard();
    const [isConsentRequired, setIsConsentRequired] = useState<boolean | undefined>();
    const [isGlobalAdminSignInRequired, setIsGlobalAdminSignInRequired] = useState<boolean | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const assertHasPermissionsRequest = useAsync<AuthenticationResult | undefined>();
    const checkUserGlobalAdminPermissionsRequest = useAsync<GlobalAdminCheckDto | undefined>();
    const { formData, setFormData } = useActivateSubscriptionFormContext();

    useEffect(() => {
        const handleInitialization = async () => {
            await assertHasPermissionsRequest.executeAsync(assertHasPermissions);
            await checkUserGlobalAdminPermissionsRequest.executeAsync(checkGlobalAdminAccess);

            if (BootstrapApplication.redirectRequestState === "redirect-error") {
                appInsights.trackEvent("User did not give required GA permissions to continue registration", TrackedEventTypeEnum.GAConsentNotGiven, TrackedEventProcessEnum.SubscriptionActivationProcess);
                setRequestedAdditionalScopesFlag(false);
            }

        };

        handleInitialization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (assertHasPermissionsRequest.error) {
            const authError = assertHasPermissionsRequest.error as AuthError;

            const shouldForceUiInteraction = BootstrapApplication.shouldForceUiInteraction(authError)
            setIsConsentRequired(shouldForceUiInteraction);

            if (authError.errorCode === 'no_account_error') {
                setStep(ActivateSubscriptionWizardSteps.SignIn);
            }
            else if (!shouldForceUiInteraction) {
                setErrorMessage(assertHasPermissionsRequest.error.message);
            }
        }
        else if (assertHasPermissionsRequest.data) {
            setIsConsentRequired(false);
        }
        else {
            setIsConsentRequired(true);
        }
    }, [assertHasPermissionsRequest, setStep])

    useEffect(() => {
        if (checkUserGlobalAdminPermissionsRequest.error) {
            const authError = checkUserGlobalAdminPermissionsRequest.error as AuthError;

            const shouldForceUiInteraction = BootstrapApplication.shouldForceUiInteraction(authError)

            if (!shouldForceUiInteraction) {
                const err = checkUserGlobalAdminPermissionsRequest.error.message;
                console.error(err);
                setErrorMessage("Error during global admin check. Please try again or contact support if the problem persists.");
            }

            setIsGlobalAdminSignInRequired(shouldForceUiInteraction);
        }
        else if (checkUserGlobalAdminPermissionsRequest.data) {
            setIsGlobalAdminSignInRequired(!checkUserGlobalAdminPermissionsRequest.data.isGlobalAdmin);
        }
        else {
            setIsGlobalAdminSignInRequired(true);
        }
    }, [checkUserGlobalAdminPermissionsRequest])

    const onGrantConsent = async () => {
        if (isConsentRequired) {
            setRequestedAdditionalScopesFlag(true);

            await getAdditionalScopes();
        }
    }

    const setRequestedAdditionalScopesFlag = (value: boolean) => {
        setFormData(val => ({ ...val, requestedAdditionalScopes: value }));
        const account = ClientApplication.instance.getActiveAccount();
        localStorage.setItem(getLocalStorageKey(account!), JSON.stringify({ ...formData, requestedAdditionalScopes: value }));
    }

    const onSignIn = async () => {
        const instance = BootstrapApplication.instance;
        // Needed to trigger jump to consent step.
        setRequestedAdditionalScopesFlag(true);
        instance.loginRedirect({
            scopes: [...ENTERPRISE_APP_LOGIN_SCOPES()],
            prompt: 'select_account',
            redirectUri: window.location.href
        });
    }

    if (assertHasPermissionsRequest.isLoading || checkUserGlobalAdminPermissionsRequest.isLoading) {
        return (
            <div className="consent-step">
                <Loading />
            </div>
        );
    }

    const actionText = isConsentRequired === true ? 'Grant consent' : 'Sign in';
    const callback = isConsentRequired === true ? onGrantConsent : onSignIn;

    return (
        <div className="consent-step">
            {(isConsentRequired === true || isGlobalAdminSignInRequired === true) &&
                <>
                    <div className="consent-step__title">{`${actionText} with your Global Administrator Account`}</div>
                    <Button className="consent-step__button" secondary onClick={callback}>
                        <span>{actionText}</span>
                    </Button>
                    <div className="consent-step__footer">
                        You will be redirected to the <br />
                        <img className="consent-step__ms-logo" src={MICROSOFT_LOGO} />
                        <b>{' '} Microsoft account sign in page</b>
                    </div>
                    <OuterLink
                        className="consent-step__link"
                        href={APP_URLS.gaNeededWhy}
                        icon
                    >
                        Why do I need to do this?
                    </OuterLink>
                </>
            }
            {isConsentRequired === false && isGlobalAdminSignInRequired === false && !errorMessage && <LoadNextStep />}
            {errorMessage && <div className="consent-step__error">{errorMessage}</div>}
        </div>
    )
}

const LoadNextStep: React.FC = () => {
    const { nextStep } = useWizard();

    useEffect(() => {
        setTimeout(nextStep, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Loading />
}