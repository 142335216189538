const getMarketingId = () => {
    const cookieValues = document.cookie.split(";");

    for (let i = 0; i < cookieValues.length; i++) {
        const cookie = cookieValues[i];
        const keyValuePair = cookie.trim().split("=");
        if (keyValuePair.length != 2 || keyValuePair[0].trim() !== '_biz_uid') {
            continue;
        }

        return keyValuePair[1];
    }

    if (process.env.NODE_ENV === 'development') {
        return 'test-cookie-id';
    }

    return undefined;
}

export { getMarketingId };

