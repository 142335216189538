/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

type IntervalCallback = () => Promise<void>;

interface UseIntervalOptions {
    maxIterations?: number;
    onTimeout?(): void;
    delayStart?: boolean;
}

export function useInterval(callback: IntervalCallback, interval: number, options: UseIntervalOptions = {}) {
    const timer = useRef<NodeJS.Timeout>();
    const refCallback = useRef(callback);
    const active = useRef(false);
    const iter = useRef(0);

    useLayoutEffect(() => {
        refCallback.current = callback;
    }, [callback]);

    const tick = useCallback(async () => {
        iter.current++;
        if (options.maxIterations !== undefined && options.maxIterations <= iter.current) {
            stop();
            options.onTimeout?.();
            return;
        }
        await refCallback.current();
        set(interval);
    }, [interval]);

    const set = useCallback((ms: number) => {
        if (timer.current !== undefined) {
            clearTimeout(timer.current);
        }

        if (active.current) {
            timer.current = setTimeout(tick, ms);
        } else {
            console.debug('useInterval.set: Unreachable state. Something went wrong.')
        }
    }, [tick, active]);

    const start = useCallback(async () => {
        const isActive = active.current;
        active.current = true;

        if (!isActive) {
            await tick();
        }

        set(interval);
    }, [tick, interval, set]);

    const stop = () => {
        if (timer.current !== undefined) {
            clearTimeout(timer.current);
        }

        active.current = false;
        timer.current = undefined;
        iter.current = 0;
    };

    useEffect(() => {
        if (options.delayStart) {
            return stop;
        }
        start();
        return stop;
    }, []);

    return { start, stop };
}