// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing{display:flex;justify-content:space-between;width:100%;height:100%}
.billing hr{width:100%}
.billing .specify-plan{display:flex;flex:1 1 auto;flex-direction:column;padding:0 30px}
.billing .specify-plan .row{display:flex;flex-direction:row;width:100%;height:-moz-fit-content;height:fit-content;margin-bottom:15px}
.billing .specify-plan .select-plan{display:flex;flex:1 1 auto;flex-direction:column}
.billing .specify-plan .select-plan .input{height:35px}
.billing .specify-plan .select-plan .plan-type .input{width:330px;margin-right:20px}
.billing .specify-plan .select-plan .plan-type p{height:16px;margin-left:10px}
.billing .specify-plan .select-plan .plan-type a{margin-top:20px;margin-left:10px}
.billing .specify-plan .select-plan .plan-type a .icon{fill:var(--link)}
.billing .specify-plan .select-plan .users-input .input-box{width:150px}
.billing .specify-plan .select-plan .users-input .alert-icon{fill:var(--red)}
.billing .specify-plan .select-plan .users-input .license-message{display:flex;flex:1 1 auto;flex-direction:row;gap:16px;align-items:center}
.billing .specify-plan .select-plan .users-input .invalid{border-color:var(--red)}
.billing .specify-plan .select-plan .billing-cycle .input{width:160px}
.billing .specify-plan .select-plan .currency{margin-left:20px}
.billing .specify-plan .select-plan .currency .input{width:190px}
.billing .specify-plan .error{color:var(--flame)}
.billing .specify-plan .footer{flex:0 0 auto}
.billing .pricing-details{display:flex;flex:1 1 auto;flex-direction:column;justify-content:space-between;width:370px;padding:0 50px;box-shadow:-8px 0 10px -5px var(--shadow-color)}
@media screen and (max-width: 1425px){.billing .pricing-details{max-width:350px}}
.billing .pricing-details .title{color:var(--cyber-grape)}
.billing .pricing-details .pricing-item{margin-bottom:5px;font-weight:bolder;font-size:1.2em}
.billing .pricing-details hr{margin-top:30px}
.billing .pricing-details .row{display:flex;flex-direction:row;align-items:baseline;justify-content:space-between}
.billing .pricing-details .buy-button{margin-bottom:15px}
.billing .pricing-details .copy-link-button{margin-bottom:7px;color:var(--eigengrau);font-weight:unset}
.billing .footer{display:flex;flex:0 0 auto;flex-direction:column;align-items:center;align-self:center;justify-content:center;width:100%;margin-bottom:10px}
.billing .footer hr{width:100%;border-top:1px solid #cacaca}
.billing .footer a{color:var(--electric-indigo)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
